import React from "react"
import { Container, Row } from "reactstrap"
const hanldeRedirect = () => {
  window.open("http://zaki.ezyro.com/?i=1", "_blank")
}
const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <div className="col-12">
              {/* Developed <span>by
              <Link onClick={hanldeRedirect}>
              <i className=""></i>🙋‍♂️ Eng zaki ahmed.
              </Link>
              </span> */}
            </div>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer
