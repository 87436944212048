import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"

import { connect } from "react-redux"
import {
  Alert,
  Button,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Form,
  Input,
} from "reactstrap"

import { Link } from "react-router-dom"

// Import menuDropdown
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"

import Sidebarlogo from "../../assets/images/hdclogo.png"
import logosmImg from "../../assets/images/icon.png"
import logodarkImg from "../../assets/images/logo-dark.png"
//i18n
import jwtDecode from "jwt-decode"
import moment from "moment"
import { withTranslation } from "react-i18next"

// Redux Store
import {
  changeSidebarType,
  showRightSidebarAction,
  toggleLeftmenu,
} from "../../store/actions"

const Header = props => {
  const [search, setsearch] = useState(false)
  const [singlebtn, setSinglebtn] = useState(false)
  const [daysRemined, setdaysRemined] = useState("")
  const [currentUser, setcurrentUser] = useState("")
  const [currentBrand, setcurrentBrand] = useState("")
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

  useEffect(() => {
    function load() {
      const token = localStorage.getItem("token")
      const data = jwtDecode(token)
      let brand = sessionStorage.getItem("brandType")
      setcurrentBrand(brand)
      let date2 = moment()
      let susbcriptionDate = moment(data?.SubscriptionExpiredDate)
      let redmindedDays = susbcriptionDate.diff(date2, "days")
      let hours = susbcriptionDate.diff(date2, "hours")
      console.log("Hours", hours)

      console.log("days", redmindedDays)
      setdaysRemined(redmindedDays)
      setcurrentUser(data.userType)
    }
    load()
  }, [])

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        )
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }

  function tToggle() {
    var body = document.body
    if (window.screen.width <= 992) {
      body.classList.toggle("sidebar-enable")
    } else {
      body.classList.toggle("vertical-collpsed")
      body.classList.toggle("sidebar-enable")
    }
  }

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logosmImg} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={logodarkImg} alt="" height="17" />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src="" alt="" height="22" />
                  {/* <img src={Sidebarlogo} alt="" height="22" /> */}
                </span>
                <span className="logo-lg mt-3">
                  <img src={Sidebarlogo} alt="" width={90} height={90} />
                </span>
              </Link>
            </div>
            <button
              type="button"
              className="btn btn-sm px-3 font-size-24 header-item waves-effect"
              id="vertical-menu-btn"
              onClick={() => {
                tToggle()
              }}
              data-target="#topnav-menu-content"
            >
              <i className="mdi mdi-menu"></i>
            </button>

            {/* <div className="d-none d-sm-block">

              <Dropdown
                isOpen={singlebtn}
                toggle={() => setSinglebtn(!singlebtn)}
                className="pt-3 d-inline-block"
              >
                <DropdownToggle className="btn btn-secondary" caret>
                  Create <i className="mdi mdi-chevron-down"></i>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem>Action</DropdownItem>
                  <DropdownItem>Another action</DropdownItem>
                  <DropdownItem>Something else here</DropdownItem>
                  <div className="dropdown-divider"></div>
                  <DropdownItem>Separated link</DropdownItem>
                </DropdownMenu>
              </Dropdown>

            </div> */}
          </div>

          <div className="d-flex">
            {/* <form className="app-search d-none d-lg-block">
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control"
                  placeholder={props.t("Search") + "..."}
                />
                <span className="fa fa-search"></span>
              </div>
            </form> */}

            <Dropdown
              className="d-inline-block d-lg-none ms-2"
              onClick={() => {
                setsearch(!search)
              }}
              type="button"
            >
              <DropdownToggle
                className="btn header-item noti-icon waves-effect"
                id="page-header-search-dropdown"
                tag="button"
              >
                {" "}
                <i className="mdi mdi-magnify"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
                <Form className="p-3">
                  <div className="form-group m-0">
                    <div className="input-group">
                      <Input
                        type="text"
                        className="form-control"
                        placeholder="Search ..."
                        aria-label="Recipient's username"
                      />
                      <div className="input-group-append">
                        <Button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify"></i>
                        </Button>
                      </div>
                    </div>
                  </div>
                </Form>
              </DropdownMenu>
            </Dropdown>
            {currentUser == "admin" && (
              <div>
                {daysRemined <= 5 && (
                  <Alert
                    color="danger"
                    className="mb-0 mt-3"
                    style={{ width: "500px", height: "40px" }}
                  >
                    <div>
                      <strong>Notice! </strong>
                      <strong>{daysRemined}</strong>
                      {` days Remined subscription time pls make new subscription!.`}
                    </div>
                  </Alert>
                )}
              </div>
            )}
            {currentUser == "superAdmin" && (
              <div>
                {daysRemined <= 5 && (
                  <Alert
                    color="danger"
                    className="mb-0 mt-3"
                    style={{ width: "500px", height: "40px" }}
                  >
                    <div>
                      <strong>Notice! </strong>
                      <strong>{daysRemined}</strong>
                      {` days Remined subscription time pls make new subscription!.`}
                    </div>
                  </Alert>
                )}
              </div>
            )}

            {/* <LanguageDropdown /> */}

            <div className="dropdown d-none d-lg-inline-block">
              <button
                type="button"
                onClick={() => {
                  toggleFullscreen()
                }}
                className="btn header-item noti-icon waves-effect"
                data-toggle="fullscreen"
              >
                <i className="mdi mdi-fullscreen"></i>
              </button>
            </div>

            {/* <NotificationDropdown /> */}

            <ProfileMenu />

            <div
              onClick={() => {
                props.showRightSidebarAction(!props.showRightSidebar)
              }}
              className="dropdown d-inline-block"
            >
              <button
                type="button"
                className="btn header-item noti-icon right-bar-toggle waves-effect"
              >
                <i className="mdi mdi-cog-outline"></i>
              </button>
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  )
}

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
}

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
    state.Layout
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType }
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header))
