import { useContext } from "react"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap"

import { AvField, AvForm } from "availity-reactstrap-validation"
import { courseContext } from "../../context/coursesContext"
import "../Dashboard/datatables.scss"

const CourseForm = () => {
  const {
    isOpen,
    editCondition,
    tog_standard,
    handleChange,
    HandleSubmitBtn,
    courseObj,
    disabled,
    load,
  } = useContext(courseContext)
  return (
    <Row>
      <Col sm={6} md={4} xl={3} className="mt-4">
        <div className="text-center"></div>

        <Modal isOpen={isOpen} toggle={tog_standard}>
          <ModalHeader
            style={{
              background: "#14213D",
              color: "white",
              border: "#0A928C",
            }}
            toggle={tog_standard}
          >
            {" "}
            <p>Create Course</p>
          </ModalHeader>
          <AvForm className="needs-validation" onValidSubmit={HandleSubmitBtn}>
            <ModalBody>
              <Row>
                <Col lg={6}>
                  <div className="mb-3">
                    <AvField
                      name="courseName"
                      type="text"
                      label="Name"
                      value={courseObj.courseName}
                      onChange={e => handleChange(e)}
                      placeholder="Enter Course Name"
                      errorMessage="Enter Course Name"
                      validate={{
                        required: { value: true },
                      }}
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mb-3">
                    <AvField
                      name="coursePrice"
                      type="number"
                      label="Price"
                      value={courseObj.coursePrice}
                      onChange={e => handleChange(e)}
                      placeholder="Enter course Price"
                      errorMessage="Enter coursePrice"
                      validate={{
                        required: { value: true },
                      }}
                    ></AvField>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <div className="mb-3">
                    <AvField
                      name="courseDuration"
                      type="select"
                      label="Duration"
                      value={courseObj.courseDuration}
                      onChange={e => handleChange(e)}
                      placeholder="Enter course Duration"
                      errorMessage="Enter course Duration"
                      validate={{
                        required: { value: true },
                      }}
                    >
                      <option vlaue="">Select Duration</option>
                      <option vlaue="1 month">1 month</option>
                      <option vlaue="2 month">2 month</option>
                      <option vlaue="3 month">3 month</option>
                      <option vlaue="4 month">4 month</option>
                      <option vlaue="5 month">5 month</option>
                      <option vlaue="5 month">6 month</option>
                      <option vlaue="7 month">7 month</option>
                      <option vlaue="8 month">8 month</option>
                      <option vlaue="9 month">9 month</option>
                      <option vlaue="10 month">10 month</option>
                      <option vlaue="11 month">11 month</option>
                      <option vlaue="12 month">12 month</option>
                    </AvField>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mb-3">
                    <AvField
                      name="courseLevel"
                      type="select"
                      label="Course Level"
                      value={courseObj.courseLevel}
                      onChange={e => handleChange(e)}
                      placeholder="Enter  course Level"
                      errorMessage="Enter courseLevel"
                      validate={{
                        required: { value: true },
                      }}
                    >
                      <option vlaue="">Select Course Level</option>
                      <option vlaue="basic">basic</option>
                      <option vlaue=" intermediate">intermediate</option>
                      <option vlaue="advanced">advanced</option>
                    </AvField>
                  </div>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button
                type="button"
                color="danger"
                onClick={tog_standard}
                className="waves-effect"
              >
                Close
              </Button>
              <Button
                type="submit"
                style={{
                  background: "#14213D",
                  color: "white",
                  border: "#0A928C",
                }}
                disabled={disabled}
                className="waves-effect waves-light"
              >
                {load ? "saving..." : "Save Course"}
              </Button>
            </ModalFooter>
          </AvForm>
        </Modal>
      </Col>
      <ToastContainer />
    </Row>
  )
}

export default CourseForm
