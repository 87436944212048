import http from "./httpServices"

const studentToken = localStorage.getItem("studentToken")
// const token = localStorage.getItem("token");
//   const headers = {
//     headers: {
//       "Content-Type": "application/json",
//       "auth-token": token
//     },
//   };
// const epiEndPoint = "http://localhost:5000";

// const epiEndPoint = "https://softwareacademy.up.railway.app";
let epiEndPoint = process.env.REACT_APP_ENDPOINT
const epiEndPointDecoration = process.env.REACT_APP_DECORATION_ENDPOINT

export async function GetAll(path, headers) {
  let brandType = sessionStorage.getItem("brandType")
  if (brandType == "decoration") {
    epiEndPoint = epiEndPointDecoration
  }
  return await http.get(epiEndPoint + "/" + path, headers)
}

export async function fetchAll(path) {
  let brandType = sessionStorage.getItem("brandType")
  if (brandType == "decoration") {
    epiEndPoint = epiEndPointDecoration
  }
  const { data } = await http.get(epiEndPoint + "/" + path)
  return data
}
export function postfunc(path, UserObj, headers) {
  let brandType = sessionStorage.getItem("brandType")
  if (brandType == "decoration") {
    epiEndPoint = epiEndPointDecoration
  }
  return http.post(epiEndPoint + "/" + path, UserObj, headers)
}
export function deletefunc(path, UserObj, headers) {
  let brandType = sessionStorage.getItem("brandType")
  if (brandType == "decoration") {
    epiEndPoint = epiEndPointDecoration
  }
  return http.delete(epiEndPoint + "/" + path + "/" + UserObj._id, headers)
}
export function putfunc(path, UsersObj, headers) {
  let brandType = sessionStorage.getItem("brandType")
  if (brandType == "decoration") {
    epiEndPoint = epiEndPointDecoration
  }

  const body = { ...UsersObj }
  delete body._id
  delete body.action
  delete body.__v
  return http.put(epiEndPoint + "/" + path + "/" + UsersObj._id, body, headers)
}
export function deleteUsers(id, headers) {
  let brandType = sessionStorage.getItem("brandType")
  if (brandType == "decoration") {
    epiEndPoint = epiEndPointDecoration
  }
  return http.delete(epiEndPoint + "/" + path + "/" + id, headers)
}
export const forgetPassword = (path, obj) => {
  let brandType = sessionStorage.getItem("brandType")
  if (brandType == "decoration") {
    epiEndPoint = epiEndPointDecoration
  }
  return http.put(epiEndPoint + "/" + path + "/passUpdate/", obj)
}
export const activationCodeSend = (path, obj) => {
  let brandType = sessionStorage.getItem("brandType")
  if (brandType == "decoration") {
    epiEndPoint = epiEndPointDecoration
  }
  return http.post(epiEndPoint + "/" + path + "/activation", obj)
}
export async function blockUsers(path, data, headers) {
  let brandType = sessionStorage.getItem("brandType")
  if (brandType == "decoration") {
    epiEndPoint = epiEndPointDecoration
  }
  return await http.put(
    epiEndPoint + "/" + path + "/blockuser/" + data._id,
    data,
    headers
  )
}
