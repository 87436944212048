import React from "react"
import MetaTags from "react-meta-tags"
import { Container } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import ClassContextProvider from "../../context/classContext"
import "../Dashboard/datatables.scss"
import ClassForm from "./classForm"
import ClassTable from "./classTable"

const classes = () => {
  return (
    <React.Fragment>
      <ClassContextProvider>
        <div className="page-content">
          <MetaTags>
            <title>Class | Human Diasgnostic Center</title>
          </MetaTags>
          <Container fluid>
            <div className="page-title-box">
              <Breadcrumbs
                maintitle="Veltrix"
                title="class"
                breadcrumbItem="class"
              />
              <ClassForm />
              <ClassTable />
            </div>
          </Container>
        </div>
      </ClassContextProvider>
    </React.Fragment>
  )
}

export default classes
