import { useContext } from "react"
import "react-toastify/dist/ReactToastify.css"
import {
  Button,
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap"

import { AvField, AvForm } from "availity-reactstrap-validation"
import { EmployeeContext } from "../../context/employeeContext"
import "../Dashboard/datatables.scss"

const EmployeeForm = () => {
  const {
    isOpen,
    editCondition,
    tog_standard,
    handleChange,
    HandleSubmitBtn,
    employeeObj,
    disabled,
    load,
  } = useContext(EmployeeContext)
  return (
    <Row>
      <Col sm={6} md={4} xl={3} className="mt-4">
        <div className="text-center"></div>
        <Modal isOpen={isOpen} toggle={tog_standard}>
          <ModalHeader
            style={{
              background: "#14213D",
              color: "white",
              border: "#0A928C",
            }}
            toggle={tog_standard}
          >
            Employee Modal
          </ModalHeader>
          <AvForm className="needs-validation" onValidSubmit={HandleSubmitBtn}>
            <ModalBody>
              <Row>
                <Col lg={6}>
                  <div className="mb-3">
                    <AvField
                      name="name"
                      type="text"
                      label="Name"
                      value={employeeObj.name}
                      onChange={e => handleChange(e)}
                      placeholder="Enter employee Name"
                      errorMessage="Enter employee Name"
                      validate={{
                        required: { value: true },
                      }}
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mb-3">
                    <AvField
                      name="phone"
                      type="number"
                      label="phone"
                      value={employeeObj.phone}
                      onChange={e => handleChange(e)}
                      placeholder="Enter phone"
                      errorMessage="Enter phone"
                      validate={{
                        required: { value: true },
                      }}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col lg={6}>
                  <div className="mb-3">
                    <AvField
                      name="gender"
                      type="select"
                      label="Gender"
                      value={employeeObj.gender}
                      onChange={e => handleChange(e)}
                      placeholder="Enter gender"
                      errorMessage="Enter gender"
                      validate={{
                        required: { value: true },
                      }}
                    >
                      <option value="">choose gender</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </AvField>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mb-3">
                    <AvField
                      name="address"
                      type="text"
                      label="address"
                      value={employeeObj.address}
                      onChange={e => handleChange(e)}
                      placeholder="Enter address"
                      errorMessage="Enter address"
                      validate={{
                        required: { value: true },
                      }}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col lg={6}>
                  <div className="mb-3">
                    <AvField
                      name="educationalLevel"
                      type="select"
                      label="educationalLevel"
                      value={employeeObj.educationalLevel}
                      onChange={e => handleChange(e)}
                      placeholder="Enter gender"
                      errorMessage="Enter gender"
                      validate={{
                        required: { value: true },
                      }}
                    >
                      <option value="">choose educational Level</option>
                      <option value="primary">primary</option>
                      <option value="High school">High school</option>
                      <option value="bachelor">Bachelor</option>
                      <option value="master">Master</option>
                      <option value="PHD">Phd</option>
                      <option value="primary">Others</option>
                    </AvField>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mb-3">
                    <AvField
                      name="jobTitle"
                      type="text"
                      label="jobTitle"
                      value={employeeObj.jobTitle}
                      onChange={e => handleChange(e)}
                      placeholder="Enter jobTitle"
                      errorMessage="Enter jobTitle"
                      validate={{
                        required: { value: true },
                      }}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col lg={6}>
                  <div className="mb-3">
                    <AvField
                      name="departmentWork"
                      type="text"
                      label="departmentWork"
                      value={employeeObj.departmentWork}
                      onChange={e => handleChange(e)}
                      placeholder="Enter departmentWork"
                      errorMessage="Enter departmentWork"
                      validate={{
                        required: { value: true },
                      }}
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mb-3">
                    <AvField
                      name="bloodType"
                      type="text"
                      label="bloodType"
                      value={employeeObj.bloodType}
                      onChange={e => handleChange(e)}
                      placeholder="Enter bloodType"
                      errorMessage="Enter bloodType"
                      validate={{
                        required: { value: true },
                      }}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col>
                  <FormGroup>
                    <Label htmlFor="">working Time</Label>
                    <AvField
                      type="select"
                      name="typeOfworking"
                      disable
                      value={employeeObj.typeOfworking}
                      onChange={e => handleChange(e)}
                      placeholder="choose working time like permanent "
                      errorMessage="choose working time like permanent"
                      validate={{
                        required: { value: true },
                      }}
                    >
                      <option value="">choose work time</option>
                      <option value="permanent">permanent worker</option>
                      <option value="part time">part time worker</option>
                      <option value="temporary">temporary worker</option>
                    </AvField>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label htmlFor="">Date Of Birth</Label>
                    <AvField
                      type="datetime-local"
                      name="dateOfBirth"
                      value={employeeObj.dateOfBirth}
                      onChange={e => handleChange(e)}
                      placeholder="Eneter date O fBirth "
                      errorMessage="Enter date Of Birth"
                      validate={{
                        required: { value: true },
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label htmlFor="">salaryAmount</Label>
                    <AvField
                      type="number"
                      name="salaryAmount"
                      value={employeeObj.salaryAmount}
                      onChange={e => handleChange(e)}
                      placeholder="Eneter salary Amount "
                      errorMessage="Enter salary Amount"
                      validate={{
                        required: { value: true },
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              {editCondition && (
                <Row>
                  <Col>
                    <FormGroup>
                      <Label htmlFor="">Employee Status</Label>
                      <AvField
                        type="select"
                        name="employeeStatus"
                        value={employeeObj.employeeStatus}
                        onChange={e => handleChange(e)}
                        placeholder="Eneter date O fBirth "
                        errorMessage="Enter date Of Birth"
                        validate={{
                          required: { value: true },
                        }}
                      >
                        <option value="">choose Employe status</option>
                        <option value="Active">Active</option>
                        <option value="Pending">Pending</option>
                        <option value="off">Off</option>
                      </AvField>
                    </FormGroup>
                  </Col>
                </Row>
              )}
            </ModalBody>
            <ModalFooter>
              <Button
                type="button"
                color="danger"
                onClick={tog_standard}
                className="waves-effect"
              >
                Close
              </Button>
              <Button
                type="submit"
                style={{
                  background: "#14213D",
                  color: "white",
                  border: "#0A928C",
                }}
                disabled={disabled}
                className="waves-effect waves-light"
              >
                {load ? "saving..." : " Save Employee"}
              </Button>
            </ModalFooter>
          </AvForm>
        </Modal>
      </Col>
    </Row>
    //   <Row>
    //   <Col sm={6} md={4} xl={3} className="mt-4">
    //     <div className="text-center"></div>

    //     <Modal   isOpen={isOpen} toggle={tog_standard}>
    //       <ModalHeader
    //         style={{
    //           background: "#0A928C",
    //           color: "white",
    //           border: "#0A928C",
    //         }}
    //         toggle={tog_standard}
    //       >
    //         {" "}
    //         <p>Create Course</p>
    //       </ModalHeader>
    //       <AvForm
    //         className="needs-validation"
    //         onValidSubmit={HandleSubmitBtn}
    //       >
    //         <ModalBody>
    //           <Row>
    //           <Col lg={6}>
    //               <div className="mb-3">
    //                  <AvField
    //                   name="courseName"
    //                   type="text"
    //                   label="Name"
    //                   value={courseObj.courseName}
    //                   onChange={(e) => handleChange(e)}
    //                   placeholder="Enter Course Name"
    //                   errorMessage="Enter Course Name"
    //                   validate={{
    //                     required: { value: true },
    //                   }}
    //                 />

    //               </div>
    //             </Col>
    //             <Col lg={6}>
    //               <div className="mb-3">
    //                 <AvField
    //                   name="coursePrice"
    //                   type="number"
    //                   label="Price"
    //                   value={courseObj.coursePrice}
    //                   onChange={(e) => handleChange(e)}
    //                   placeholder="Enter course Price"
    //                   errorMessage="Enter coursePrice"
    //                   validate={{
    //                     required: { value: true },
    //                   }}
    //                 >
    //                 </AvField>
    //               </div>
    //             </Col>
    //           </Row>
    //           <Row>
    //             <Col lg={6}>
    //               <div className="mb-3">
    //               <AvField
    //                   name="courseDuration"
    //                   type="select"
    //                   label="Price"
    //                   value={courseObj.courseDuration}
    //                   onChange={(e) => handleChange(e)}
    //                   placeholder="Enter course Duration"
    //                   errorMessage="Enter course Duration"
    //                   validate={{
    //                     required: { value: true },
    //                   }}
    //                 >
    //                   <option vlaue="">Select Duration</option>
    //                   <option vlaue="1 month">1 month</option>
    //                   <option vlaue="2 month">2 month</option>
    //                   <option vlaue="3 month">3 month</option>
    //                   <option vlaue="4 month">4 month</option>
    //                   <option vlaue="5 month">5 month</option>
    //                   <option vlaue="5 month">6 month</option>
    //                   <option vlaue="7 month">7 month</option>
    //                   <option vlaue="8 month">8 month</option>
    //                   <option vlaue="9 month">9 month</option>
    //                   <option vlaue="10 month">10 month</option>
    //                   <option vlaue="11 month">11 month</option>
    //                   <option vlaue="12 month">12 month</option>
    //                 </AvField>
    //               </div>
    //             </Col>
    //             <Col lg={6}>
    //               <div className="mb-3">
    //               <AvField
    //                   name="courseLevel"
    //                   type="select"
    //                   label="Price"
    //                   value={courseObj.courseLevel}
    //                   onChange={(e) => handleChange(e)}
    //                   placeholder="Enter  course Level"
    //                   errorMessage="Enter courseLevel"
    //                   validate={{
    //                     required: { value: true },
    //                   }}
    //                 >
    //                   <option vlaue="">Select Course Level</option>
    //                   <option vlaue="basic">basic</option>
    //                   <option vlaue=" intermediate">intermediate</option>
    //                   <option vlaue="advanced">advanced</option>

    //                 </AvField>
    //               </div>
    //             </Col>

    //           </Row>

    //         </ModalBody>
    //         <ModalFooter>
    //           <Button
    //             type="button"
    //             color="danger"
    //             onClick={tog_standard}
    //             className="waves-effect"
    //           >
    //             Close
    //           </Button>
    //           <Button
    //             type="submit"
    //             color="success"
    //             className="waves-effect waves-light"
    //           >
    //             Save Course
    //           </Button>
    //         </ModalFooter>
    //       </AvForm>
    //     </Modal>
    //   </Col>
    //   <ToastContainer />
    // </Row>
  )
}

export default EmployeeForm
