import axios from "axios"
import moment from "moment"
import { createContext, useState } from "react"
import { useLocation } from "react-router-dom"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import useSwr, { mutate } from "swr"
import { postfunc, putfunc } from "../services/mainService"

export const subscriptionContext = createContext()
subscriptionContext.displayName = "subscriptionContext"

const SubscriptionContextProvider = props => {
  const [susbcriptionObj, setsusbcriptionObj] = useState({
    subscriptoinTerm: "",
    amountPaid: "",
    PhonePaid: "",
    accountType: "",
    datePaid: "",
    expiredDate: "",
  })
  const [editCondition, setEditCondition] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [disabled, setdisabled] = useState(false)
  const [load, setload] = useState(false)
  const location = useLocation()
  // Auhtentication(location.pathname)
  //   useSwr("branch",getMyprint);

  const token = localStorage.getItem("token")
  const headers = {
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
  }
  let epiEndPoint = process.env.REACT_APP_ENDPOINT

  async function fetchAll(path) {
    const { data } = await axios.get(epiEndPoint + "/" + path, headers)
    return data
  }

  let { data: subscriptionList } = useSwr("subscription", fetchAll)

  let subscriptionData = []
  let chechArray = Array.isArray(subscriptionList)
  if (chechArray == true) {
    subscriptionData = subscriptionList
  }
  const notify = (message, type) => toast[type](message)
  const tog_standard = () => {
    setIsOpen(!isOpen)
    setsusbcriptionObj({
      subscriptoinTerm: "",
      amountPaid: "",
      PhonePaid: "",
      accountType: "",
      datePaid: "",
      expiredDate: "",
    })
    setEditCondition(false)
  }

  const HandleSubmitBtn = async e => {
    try {
      setdisabled(true)
      setload(true)
      if (editCondition) {
        const { data } = await putfunc("subscription", susbcriptionObj, headers)
        const { status, message, info } = { ...data }
        if (status) {
          setdisabled(false)
          setload(false)
          mutate("subscription", fetchAll)
          notify(message, "success")
          setIsOpen(false)
          setsusbcriptionObj({
            subscriptoinTerm: "",
            amountPaid: "",
            PhonePaid: "",
            accountType: "",
            datePaid: "",
            expiredDate: "",
          })
        } else {
          notify(message, "error")
          setdisabled(false)
          setload(false)
        }
      } else {
        // notify("welcome", "error");
        // return console.log("susbcriptionObj", susbcriptionObj)
        delete susbcriptionObj.expiredDate
        const { data } = await postfunc(
          "subscription",
          susbcriptionObj,
          headers
        )
        const { status, message, info } = { ...data }
        if (status) {
          setdisabled(false)
          setload(false)
          mutate("subscription", fetchAll)
          setsusbcriptionObj({
            subscriptoinTerm: "",
            amountPaid: "",
            PhonePaid: "",
            accountType: "",
            datePaid: "",
            expiredDate: "",
          })
          notify(message, "success")
          setIsOpen(false)
        } else {
          notify(message, "error")
          setdisabled(false)
          setload(false)
        }
      }
    } catch (error) {
      console.log(error.response)
      notify(error.message, "error")
      setdisabled(false)
      setload(false)
    }
  }

  const handleChange = e => {
    setsusbcriptionObj(prev => {
      return { ...prev, [e.target.name]: e.target.value }
    })
  }
  const handleEdit = async transData => {
    let data = { ...transData }
    data.expiredDate = moment(data.expiredDate).format("YYYY-MM-DD[T]HH:mm:ss")
    data.datePaid = moment(data.datePaid).format("YYYY-MM-DD[T]HH:mm:ss")

    setEditCondition(true)
    setsusbcriptionObj(data)
    setIsOpen(true)
  }

  return (
    <subscriptionContext.Provider
      value={{
        handleEdit,
        handleChange,
        HandleSubmitBtn,
        tog_standard,
        isOpen,
        setIsOpen,
        subscriptionData,
        editCondition,
        susbcriptionObj,
        disabled,
        load,
      }}
    >
      {props.children}
    </subscriptionContext.Provider>
  )
}

export default SubscriptionContextProvider
