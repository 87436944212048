import { useContext } from "react"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import {
  Button,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap"

import { AvField, AvForm } from "availity-reactstrap-validation"
import Select from "react-select"
import { receiptContext } from "../../context/receiptContext"
import "../Dashboard/datatables.scss"

const ReceiptForm = () => {
  const {
    isOpen,
    editCondition,
    CustomerData,
    tog_standard,
    handleEdit,
    handleChange,
    StudentONChenge,
    FeeONChenge,
    HandleSubmitBtn,
    feeData,
    studentData,
    receiptObj,
    disabled,
    load,
    selectedStudent,
  } = useContext(receiptContext)
  return (
    <Row>
      <Col sm={6} md={4} xl={3} className="mt-4">
        <div className="text-center"></div>

        <Modal isOpen={isOpen} toggle={tog_standard}>
          <ModalHeader
            style={{
              background: "#14213D",
              color: "white",
              border: "#0A928C",
            }}
            toggle={tog_standard}
          >
            {" "}
            <p>Make Receipt</p>
          </ModalHeader>
          <AvForm className="needs-validation" onValidSubmit={HandleSubmitBtn}>
            <ModalBody>
              <Row>
                <Col lg={6}>
                  <div className="mb-3">
                    {/* <AvField
                      type="select"
                      name="studentID"
                      label="student"
                      value={receiptObj.studentID}
                      onChange={(e) => StudentONChenge(e)}
                      className="form-control "
                      errorMessage="please student "
                      validate={{ required: { value: true } }}
                    >
                      <option vale="">Select student</option>
                      {studentData?.map((std) => (
                        <option value={std._id}>{std.studentName+"-"+std.stdID}</option>
                      ))}
                    </AvField> */}
                    <Label>Select Student</Label>

                    <Select
                      name="studentID"
                      value={selectedStudent}
                      // isClearable={true}

                      isSearchable={true}
                      onChange={e => StudentONChenge(e)}
                      options={studentData?.map((student, index) => {
                        return {
                          label: student.studentName + "-" + student.stdID,
                          value: student._id,
                          key: index,
                        }
                      })}
                    ></Select>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mb-3">
                    <AvField
                      type="select"
                      name="feeID"
                      label="fee"
                      value={receiptObj.feeID}
                      onChange={e => FeeONChenge(e)}
                      className="form-control"
                      errorMessage="please fee"
                      validate={{ required: { value: true } }}
                    >
                      <option vale="">Select Fee</option>
                      {feeData?.map(fee => (
                        <option value={fee._id}>{fee.feeName}</option>
                      ))}
                    </AvField>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col lg={6}>
                  <div className="mb-3">
                    <AvField
                      name="phonePaid"
                      type="number"
                      label="phone Paid"
                      value={receiptObj.phonePaid}
                      onChange={e => handleChange(e)}
                      placeholder="Enter phonePaid"
                      errorMessage="Enter phone Paid"
                      validate={{
                        required: { value: true },
                      }}
                    ></AvField>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mb-3">
                    <AvField
                      name="phoneReceipt"
                      type="number"
                      label="Receipt phone"
                      value={receiptObj.phoneReceipt}
                      onChange={e => handleChange(e)}
                      placeholder="Enter phoneReceipt"
                      errorMessage="Enter phone Paid"
                      validate={{
                        required: { value: true },
                      }}
                    ></AvField>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <div className="mb-3">
                    <AvField
                      name="AmountPaid"
                      type="number"
                      label="AmountPaid"
                      value={receiptObj.AmountPaid}
                      onChange={e => handleChange(e)}
                      placeholder="Enter Amount Paid"
                      errorMessage="Enter Amount Paid"
                      validate={{
                        required: { value: true },
                      }}
                    ></AvField>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mb-3">
                    <AvField
                      name="datePaid"
                      type="datetime-local"
                      label="date Receipt"
                      value={receiptObj.datePaid}
                      onChange={e => handleChange(e)}
                      placeholder="Enter date Receipt"
                      errorMessage="Enter date Receipt"
                      validate={{
                        required: { value: true },
                      }}
                    ></AvField>
                  </div>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button
                type="button"
                color="danger"
                onClick={tog_standard}
                className="waves-effect"
              >
                Close
              </Button>
              <Button
                type="submit"
                style={{
                  background: "#14213D",
                  color: "white",
                  border: "#0A928C",
                }}
                disabled={disabled}
                className="waves-effect waves-light"
              >
                {load ? "saving..." : "Save Receipt"}
              </Button>
            </ModalFooter>
          </AvForm>
        </Modal>
      </Col>
      <ToastContainer />
    </Row>
  )
}

export default ReceiptForm
