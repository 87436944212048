import { useContext } from "react"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap"

import { AvField, AvForm } from "availity-reactstrap-validation"
import { IncomeContext } from "../../context/incomeContext"
import "../Dashboard/datatables.scss"

const IncomeForm = () => {
  const {
    isOpen,
    editCondition,
    tog_standard,
    handleChange,
    HandleSubmitBtn,
    IncomeObj,
    disabled,
    load,
    brandType,
  } = useContext(IncomeContext)
  return (
    <Row>
      <Col sm={6} md={4} xl={3} className="mt-4">
        <div className="text-center"></div>

        <Modal isOpen={isOpen} toggle={tog_standard}>
          <ModalHeader
            style={{
              background: "#14213D",
              color: "white",
              border: "#0A928C",
            }}
            toggle={tog_standard}
          >
            {" "}
            <p>New Income</p>
          </ModalHeader>
          <AvForm className="needs-validation" onValidSubmit={HandleSubmitBtn}>
            <ModalBody>
              <Row>
                <Col lg={6}>
                  <div className="mb-3">
                    <AvField
                      name="type"
                      type="select"
                      label="Income Type"
                      value={IncomeObj.type}
                      onChange={e => handleChange(e)}
                      placeholder="Enter  Type"
                      errorMessage="Enter  Type"
                      validate={{
                        required: { value: true },
                      }}
                    >
                      <option vlaue="">Select Income Type</option>
                      <option vlaue="service">service income</option>

                      {brandType == "decoration" ? (
                        <option vlaue="student">Sales Income</option>
                      ) : null}

                      {brandType == "academy" ? (
                        <option vlaue="student">student</option>
                      ) : null}
                    </AvField>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mb-3">
                    <AvField
                      name="name"
                      type="text"
                      label={`${
                        brandType == "decoration"
                          ? "service name or sales "
                          : "service name or student"
                      }`}
                      value={IncomeObj.name}
                      onChange={e => handleChange(e)}
                      placeholder="Enter income from"
                      errorMessage="Enter income from"
                      validate={{
                        required: { value: true },
                      }}
                    ></AvField>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <div className="mb-3">
                    <AvField
                      name="amount"
                      type="number"
                      label="amount"
                      value={IncomeObj.amount}
                      onChange={e => handleChange(e)}
                      placeholder="Enter amount "
                      errorMessage="Enter amount"
                      validate={{
                        required: { value: true },
                      }}
                    ></AvField>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mb-3">
                    <AvField
                      name="date"
                      type="datetime-local"
                      label="Income Date"
                      value={IncomeObj.date}
                      onChange={e => handleChange(e)}
                      placeholder="Enter date "
                      errorMessage="Enter date"
                      validate={{
                        required: { value: true },
                      }}
                    ></AvField>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <div className="mb-3">
                    <AvField
                      name="description"
                      type="textarea"
                      maxlength="255"
                      label="description"
                      placeholder="This textarea has a limit of 225 chars."
                      value={IncomeObj.description}
                      onChange={e => handleChange(e)}
                      errorMessage="Enter description"
                      validate={{
                        required: { value: true },
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button
                type="button"
                color="danger"
                onClick={tog_standard}
                className="waves-effect"
              >
                Close
              </Button>
              <Button
                type="submit"
                style={{
                  background: "#14213D",
                  color: "white",
                  border: "#0A928C",
                }}
                disabled={disabled}
                className="waves-effect waves-light"
              >
                {load ? "saving..." : " Save Income"}
              </Button>
            </ModalFooter>
          </AvForm>
        </Modal>
      </Col>
      <ToastContainer />
    </Row>
  )
}

export default IncomeForm
