import { AvForm } from "availity-reactstrap-validation"
import jsPDF from "jspdf"
import { MDBDataTable } from "mdbreact"
import moment from "moment"
import { useContext, useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Table,
} from "reactstrap"
import { mutate } from "swr"
import headerImage from "../../assets/images/hdclogo.png"
import { receiptContext } from "../../context/receiptContext"
import "../Dashboard/datatables.scss"
const ReceiptTable = () => {
  const [receiptprintObj, setreceiptprintObj] = useState({})
  const [openReceipt, setopenReceipt] = useState(false)
  const [disabled, setdisabled] = useState(false)
  const [load, setload] = useState(false)
  const {
    isOpen,
    editCondition,
    receiptData,
    tog_standard,
    handleEdit,
    setsweetAlertConfirm,
    sweetAlertConfirm,
    handleDelete,
    deletefunc,
    fetchAll,
    receiptObj,
  } = useContext(receiptContext)

  function handleViewReceipt(data) {
    console.log(data)
    setreceiptprintObj(data)
    setopenReceipt(true)
  }
  const tog_standardReceipt = () => {
    setopenReceipt(!openReceipt)
  }
  const token = localStorage.getItem("token")
  const headers = {
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
  }

  const data = {
    columns: [
      {
        label: "No",
        field: "receiptNo",
        sort: "asc",
        width: 200,
      },

      {
        label: "student",
        field: "studentName",
        sort: "asc",
        width: 270,
      },
      {
        label: "feeName",
        field: "feeName",
        sort: "asc",
        width: 150,
      },

      {
        label: "phonePaid",
        field: "phonePaid",
        sort: "asc",
        width: 100,
      },
      {
        label: "phoneReceipt",
        field: "phoneReceipt",
        sort: "asc",
        width: 100,
      },
      {
        label: "Amount",
        field: "receiptAmount",
        sort: "asc",
        width: 100,
      },
      // {
      //   label: "receiptStatus",
      //   field: "receiptStatus",
      //   sort: "asc",
      //   width: 100,
      // },

      {
        label: "datePaid",
        field: "date",
        sort: "asc",
        width: 100,
      },

      {
        label: "print",
        field: "print",
        sort: "asc",
        width: 100,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 100,
      },
    ],
    rows: receiptData?.map(hos => {
      const data = { ...hos }
      // data.studentName = data.studentID?.studentName;
      data.studentName =
        data.studentID?.studentName + " -" + data.studentID?.stdID
      data.stdID = data.studentID?.stdID
      data.userReceipt = data.userID?.name
      data.feeName = data.feeID?.feeName
      data.feeNo = data.feeID?.feeNo

      data.receiptAmount = "$" + data.AmountPaid
      data.date = moment(data.datePaid).format("lll")

      data.action = (
        <div>
          <button
            onClick={() => handleEdit(hos)}
            type="button"
            className="btn btn-white btn-sm mr-4"
          >
            <i
              style={{
                color: "#14213D",
              }}
              className="mdi mdi-pencil font-size-20 "
            ></i>
          </button>
          <button
            onClick={() => handleDelete(data)}
            className="btn btn-white btn-sm mr-4"
          >
            <i className="dripicons-trash text-danger font-size-20"></i>
          </button>
        </div>
      )
      data.print = (
        <button
          type="button"
          className="btn btn-white btn-sm"
          onClick={() => handleViewReceipt(data)}
        >
          <i
            style={{ color: "#E88004" }}
            className="ion ion-md-print ms-2 font-size-20 "
          ></i>
        </button>
      )
      return data
    }),
  }

  const handleGeneratePdf = () => {
    try {
      setload(true)
      setdisabled(true)
      const els = document.getElementsByClassName("printablediv")
      const pdf = new jsPDF("p", "px", "a4")
      pdf.html(els[0]).then(() => {
        pdf.save("receipt.pdf")
        setload(false)
        setdisabled(false)
      })
    } catch (error) {
      console.log(Error)
      setload(false)
    }
  }
  return (
    <Row>
      <Col className="col-12">
        <Card>
          <CardBody>
            <Row className="align-items-center  mb-3">
              <Col md={8}>
                <CardTitle className="h4">Receipts Table </CardTitle>
              </Col>

              <Col md="4">
                <div className="float-end d-none d-md-block">
                  <Button
                    onClick={tog_standard}
                    style={{
                      background: "#14213D",
                      color: "white",
                      border: "#0A928C",
                    }}
                    className=" dropdown-toggle waves-effect waves-light"
                  >
                    <i className="ion ion-md-add me-2"></i> Add Receipt
                  </Button>
                </div>
              </Col>
            </Row>
            <ToastContainer />

            <MDBDataTable responsive striped bordered data={data} />
          </CardBody>
        </Card>
      </Col>
      {sweetAlertConfirm ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmButtonText="Yes, delete it!"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={async () => {
            try {
              // return  console.log(receiptObj)
              const { data } = await deletefunc("receipt", receiptObj, headers)
              const { status, message } = { ...data }
              if (status) {
                toast.success(message)
                setsweetAlertConfirm(false)
                mutate("receipt", fetchAll)
              } else {
                toast.error(message)
              }
            } catch (error) {
              toast.error(error.message)
            }
          }}
          onCancel={() => setsweetAlertConfirm(false)}
        >
          You won't be able to revert this!
        </SweetAlert>
      ) : null}

      <Modal isOpen={openReceipt} toggle={tog_standardReceipt}>
        {/* <ModalHeader toggle={tog_standard}>Qoutation full info</ModalHeader> */}
        <AvForm>
          <ModalBody className="printablediv">
            <Row className="qoutation-title  " style={{ marginLeft: "-29px" }}>
              <div className="col-6">
                <img
                  crossorigin="anonymous"
                  src={headerImage}
                  height="100"
                  width="100"
                  alt="logo"
                />
              </div>

              {/* <div  className="col-6 ">
                  <address>
                            <h3>Receipt</h3>
                          </address>

                  </div> */}
            </Row>
            {/* <Row className="qoutation-title mt-4 ml-2">
                  <Col>
                    <p className="">
                      Date: <span>{receiptprintObj.date}</span>
                    </p>
                  </Col>

                  <Col className="float-right">
                    <p className="">
                      Receipt No: <span>{receiptprintObj.receiptNo}</span>
                    </p>
                  </Col>
                </Row> */}
            <Row>
              <div className="col-6 mt-2">
                <address>
                  <strong>RNO:{receiptprintObj.receiptNo}</strong>
                </address>
              </div>
              <div className="col-6 mt-2 ">
                <address>
                  <strong>Date: {receiptprintObj.date}</strong>
                </address>
              </div>
            </Row>
            <Row>
              <div className="col-6">
                <address>
                  <strong>From:</strong>
                  <br />
                  Softiweer academy
                  <br />
                  Bakaro,mogdishu-somalia
                  <br />
                  +252 614886667
                </address>
              </div>
              <div className="col-6">
                <address>
                  <strong>To:</strong>
                  <br />
                  {receiptprintObj.studentName}
                  <br />
                  {/* {receiptprintObj.stdID}
                            <br /> */}
                </address>
              </div>
            </Row>

            <div className="table-responsive ">
              <Table className="table-responsive">
                <thead>
                  <tr>
                    <th scope="col">FNO</th>
                    <th scope="col">Fee Name</th>
                    <th></th>
                    <th></th>
                    <th scope="col">Receipt Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <h6>{receiptprintObj.feeNo}</h6>
                    </td>
                    <td>
                      <h6>{receiptprintObj.feeName}</h6>
                    </td>
                    <td></td>
                    <td></td>
                    <td>
                      <h6>{receiptprintObj.receiptAmount}</h6>
                    </td>
                  </tr>
                </tbody>
              </Table>

              {/* <Row> */}
              <div className="col-6 ">
                <address>
                  <strong>Payment Method:Evc</strong>
                  <br />
                  Phone Paid: {receiptprintObj.phonePaid}
                  <br />
                  Receipt by:{receiptprintObj.userReceipt}
                </address>
              </div>
              {/* <div className="col-6 mt-4 text-end">
                          <address>
                            <strong>Order Date:</strong>
                            <br />
                            January 16, 2019
                            <br />
                            <br />
                          </address>
                        </div> */}
              <p>Thank you dear student</p>
              {/* </Row> */}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              onClick={tog_standardReceipt}
              color="danger"
              className="waves-effect"
            >
              Close
            </Button>
            <Button
              color="success"
              type="button"
              disabled={disabled}
              onClick={handleGeneratePdf}
              className="waves-effect waves-light ml-4 d-print-none"
            >
              <i className="ri-printer-line align-middle "></i>
              {load ? "downloading..." : "Download"}
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>
    </Row>
  )
}

export default ReceiptTable
