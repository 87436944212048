import React from "react"
import MetaTags from "react-meta-tags"
import { Container } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import CourseContextProviderr from "../../context/coursesContext"
import "../Dashboard/datatables.scss"
import CourseForm from "./courseForm"
import CoursesTable from "./coursesTable"

const Courses = () => {
  return (
    <React.Fragment>
      <CourseContextProviderr>
        <div className="page-content">
          <MetaTags>
            <title>Courses | Human Diasgnostic Center</title>
          </MetaTags>
          <Container fluid>
            <div className="page-title-box">
              <Breadcrumbs
                maintitle="Veltrix"
                title="class"
                breadcrumbItem="class"
              />
              <CourseForm />
              <CoursesTable />
            </div>
          </Container>
        </div>
      </CourseContextProviderr>
    </React.Fragment>
  )
}

export default Courses
