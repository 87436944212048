import moment from "moment"
import { createContext, useEffect, useState } from "react"

import { useLocation } from "react-router-dom"
import Auhtentication from ".././authMidlleware/userMiddleWare"
import { GetAll, deletefunc, postfunc, putfunc } from "../services/mainService"

import axios from "axios"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import useSwr, { mutate } from "swr"
export const feeContext = createContext()
feeContext.displayName = "feeContext"

const FeeContextProvider = props => {
  const [feeObj, setfeeObj] = useState({
    feeName: "",
    feetype: "",
    classID: "",
    feeAmount: 0,
  })
  const [receiptObj, setreceiptObj] = useState({
    phonePaid: "",
    phoneReceipt: "",
    AmountPaid: "",
    datePaid: "",
  })
  const [editCondition, setEditCondition] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [Showreceipt, setShowReceipt] = useState(false)
  const [classData, setclassData] = useState([])
  const [classDataTemp, setclassTemp] = useState([])
  const [disabled, setdisabled] = useState(false)
  const [load, setload] = useState(false)
  const [sweetAlertConfirm, setsweetAlertConfirm] = useState(false)
  const [Students, setStudents] = useState([])
  const [studentID, setstudentID] = useState("")
  const [radioBoxValue, setradioBoxValue] = useState("class")
  const [classload, setclassload] = useState(false)
  const location = useLocation()
  Auhtentication(location.pathname)
  const token = localStorage.getItem("token")
  const headers = {
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
  }

  let epiEndPoint = process.env.REACT_APP_ENDPOINT

  async function fetchAll(path) {
    const { data } = await axios.get(epiEndPoint + "/" + path, headers)
    return data
  }

  useEffect(() => {
    const getclass = async () => {
      try {
        const { data: classesData } = await GetAll("class", headers)
        const { data: StudentsData } = await GetAll("student", headers)

        let checkclass = Array.isArray(classesData)
        let checkstudents = Array.isArray(StudentsData)
        if (checkstudents) {
          setStudents(StudentsData)
        }
        if (checkclass) {
          setclassData(classesData)
          setclassTemp(classesData)
        }
      } catch (error) {
        console.log(error.message)
      }
    }
    getclass()
  }, [])

  let { data: feeList } = useSwr("fee", fetchAll)
  let feeData = []

  let chechArray = Array.isArray(feeList)
  if (chechArray == true) {
    feeData = feeList
  }
  const notify = (message, type) => toast[type](message)
  const tog_standard = () => {
    setIsOpen(!isOpen)
    setfeeObj({
      feeName: "",
      feetype: "",
      classID: "",
      feeAmount: "",
    })
    setEditCondition(false)
    setradioBoxValue("class")
    setstudentID("")
  }

  const Receipt_tog = data => {
    setShowReceipt(!Showreceipt)
    setfeeObj(data)

    setreceiptObj({
      phonePaid: receiptObj.phonePaid,
      phoneReceipt: receiptObj.phoneReceipt,
      AmountPaid: data.feeBalance,
      datePaid: receiptObj.datePaid,
    })
  }

  const HandleSubmitBtn = async e => {
    try {
      setdisabled(true)
      // setload(true)

      // let path = "fee"
      // if (feeObj.classID == "All") {
      //   path = "fee/allClasses"
      // }
      // return console.log("feeObj", feeObj)
      if (editCondition) {
        const { data } = await putfunc("fee", feeObj, headers)
        const { status, message, info } = { ...data }
        if (status) {
          setdisabled(false)
          setload(false)
          mutate("fee", fetchAll)
          notify(message, "success")
          setIsOpen(false)
          setfeeObj({
            feeName: "",
            feetype: "",
            classID: "",
            feeAmount: "",
          })
        } else {
          notify(message, "error")
          setdisabled(false)
          setload(false)
        }
      } else {
        // notify("welcome", "error");
        if (feeObj.feetype == "monthlyfee") {
          feeObj.feeAmount = 0
        }
        // return console.log("feeObj", feeObj)
        let path = ""
        if (radioBoxValue == "single") {
          if (studentID.value == undefined) {
            return notify("pls select Student", "error")
          }
          feeObj.studentID = studentID.value
          path = "fee/generateFeeSingleStudent"
        } else {
          path = "fee"
        }

        if (radioBoxValue != "single" && feeObj.classID == "All") {
          path = "fee/allClasses"
        }
        // return console.log(path)

        const { data } = await postfunc(path, feeObj, headers)

        const { status, message } = { ...data }
        if (status) {
          if (radioBoxValue == "single") {
            setclassData(classDataTemp)
            setradioBoxValue("class")
            setstudentID("")
          }
          setdisabled(false)
          setload(false)
          mutate("fee", fetchAll)
          setfeeObj({
            feeName: "",
            feetype: "",
            classID: "",
            feeAmount: "",
          })
          notify(message, "success")
          setIsOpen(false)
        } else {
          notify(message, "error")
          setdisabled(false)
          setload(false)
        }
      }
    } catch (error) {
      // console.log(error.response)
      notify(error.message, "error")
      setdisabled(false)
      setload(false)
    }
  }
  const HandleSubmitReceipt = async e => {
    try {
      if (editCondition) {
        const { data } = await putfunc("receipt", feeObj, headers)
        const { status, message, info } = { ...data }
        if (status) {
          mutate("receipt", fetchAll)
          notify(message, "success")
          setShowReceipt(false)
          setreceiptObj({
            phonePaid: "",
            phoneReceipt: "",
            AmountPaid: "",
            datePaid: "",
          })
        }
      } else {
        // notify("welcome", "error");

        let mainObj = {
          feeID: feeObj._id,
          studentID: feeObj.studentID?._id,
          phonePaid: receiptObj.phonePaid,
          phoneReceipt: receiptObj.phoneReceipt,
          AmountPaid: receiptObj.AmountPaid,
          datePaid: receiptObj.datePaid,
        }
        const { data } = await postfunc("receipt", mainObj, headers)
        const { status, message, info } = { ...data }
        if (status) {
          mutate("fee", fetchAll)

          notify(message, "success")
          setShowReceipt(false)
          setreceiptObj({
            phonePaid: "",
            phoneReceipt: "",
            AmountPaid: "",
            datePaid: "",
          })
        } else {
          notify(message, "error")
        }
      }
    } catch (error) {
      console.log(error.response)
      notify(error.message, "error")
    }
  }

  const handleChangeReceipt = e => {
    setreceiptObj(prev => {
      return { ...prev, [e.target.name]: e.target.value }
    })
  }
  const handleChange = e => {
    setfeeObj(prev => {
      return { ...prev, [e.target.name]: e.target.value }
    })
  }
  const RadioBoxChange = e => {
    setradioBoxValue(e.target.value)
    // console.log(e.target.value)
    if ((e.target.value = "class")) {
      setclassData(classDataTemp)
      setstudentID("")
    }
  }
  const handleEdit = async transData => {
    let data = { ...transData }
    data.courseID = data.courseID?._id
    data.teacherID = data.teacherID?._id

    data.startedDate = moment(data.startedDate).format("YYYY-MM-DD")
    data.endDate = moment(data.endDate).format("YYYY-MM-DD")
    data.date = new Date(data.date)
    setEditCondition(true)
    setfeeObj(data)
    setIsOpen(true)
  }
  const handleDelete = data => {
    console.log(data)
    setfeeObj(data)
    setsweetAlertConfirm(true)
  }

  // const StudentIDOnachnge = e => {
  //   try {
  //     console.log(e)
  //     if (e.value) {
  //       setstudentID(e)
  //     }
  //   } catch (error) {
  //     console.log(e)
  //     console.log(error)
  //   }
  // }

  let StudentIDOnachnge = async event => {
    try {
      setclassload(true)
      if (event.value) {
        setstudentID(event)
      }
      const { data } = await axios.get(
        `${epiEndPoint}/certificate/studentClases/${event.value}`,
        headers
      )
      if (data.status) {
        if (Array.isArray(data.studentsClasses)) {
          setclassData(data.studentsClasses)
        }
      } else {
        toast.error(data.message)
      }
      setclassload(false)
    } catch (error) {
      toast.error(error.message)
      setclassload(false)
    }
  }
  return (
    <feeContext.Provider
      value={{
        handleEdit,
        classload,
        RadioBoxChange,
        radioBoxValue,
        StudentIDOnachnge,
        studentID,
        handleChange,
        handleChangeReceipt,
        HandleSubmitBtn,
        HandleSubmitReceipt,
        tog_standard,
        Receipt_tog,
        isOpen,
        setIsOpen,
        feeData,
        editCondition,
        feeObj,
        receiptObj,
        Showreceipt,
        classData,
        disabled,
        load,
        setsweetAlertConfirm,
        sweetAlertConfirm,
        handleDelete,
        deletefunc,
        fetchAll,
        Students,
      }}
    >
      {props.children}
    </feeContext.Provider>
  )
}

export default FeeContextProvider
