import axios from "axios"
import moment from "moment"
import { createContext, useState } from "react"
import { useLocation } from "react-router-dom"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import useSwr, { mutate } from "swr"
import Auhtentication from ".././authMidlleware/userMiddleWare"
import { postfunc, putfunc } from "../services/mainService"
export const studentsContext = createContext()
studentsContext.displayName = "studentsContext"

const StudentsContextProvider = props => {
  const [studentObj, setstudentObj] = useState({
    studentName: "",
    studentPhone: "",
    studentGender: "",
    studentAddress: "",
    educationalLevel: "",
    studentStatus: "",
    username: "",
    password: "",
    parentName: "",
    parentPhone: "",
  })
  const [editCondition, setEditCondition] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [disabled, setdisabled] = useState(false)
  const [load, setload] = useState(false)
  const [showpassword, setshowpassword] = useState(false)
  const [showicon, setshowicon] = useState("mdi mdi-eye")
  const location = useLocation()
  // console.log("current Path",location.pathname);
  Auhtentication(location.pathname)

  const token = localStorage.getItem("token")

  const headers = {
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
  }
  let epiEndPoint = process.env.REACT_APP_ENDPOINT

  async function fetchAll(path) {
    const { data } = await axios.get(epiEndPoint + "/" + path, headers)
    return data
  }

  //   useSwr("branch",getMyprint);
  let { data: studentList, error } = useSwr("student", fetchAll)
  let studentData = []

  let chechArray = Array.isArray(studentList)

  if (chechArray == true) {
    studentData = studentList
  }

  const notify = (message, type) => toast[type](message)
  const tog_standard = () => {
    setIsOpen(!isOpen)
    setstudentObj({
      studentName: "",
      studentPhone: "",
      studentGender: "",
      studentAddress: "",
      educationalLevel: "",
      studentStatus: "",
      username: "",
      password: "",
      parentName: "",
      parentPhone: "",
    })

    setEditCondition(false)
  }

  const HandleSubmitBtn = async e => {
    try {
      if (studentObj.password == "")
        return notify("password is required", "error")
      setdisabled(true)
      setload(true)
      if (editCondition) {
        const { data } = await putfunc("student", studentObj, headers)
        const { status, message, info } = { ...data }
        if (status) {
          setdisabled(false)
          setload(false)
          mutate("student", fetchAll)
          notify(message, "success")
          setIsOpen(false)
          setstudentObj({
            studentName: "",
            studentPhone: "",
            studentGender: "",
            studentAddress: "",
            educationalLevel: "",
            studentStatus: "",
            username: "",
            password: "",
            parentName: "",
            parentPhone: "",
          })
        } else {
          notify(message, "error")
          setdisabled(false)
          setload(false)
        }
      } else {
        // notify("welcome", "error");
        if (studentObj.studentStatus == "") {
          delete studentObj.studentStatus
        }

        // return console.log("studentObj", studentObj)

        const { data } = await postfunc("student", studentObj, headers)
        const { status, message, info } = { ...data }
        if (status) {
          setdisabled(false)
          setload(false)
          mutate("student", fetchAll)
          setstudentObj({
            studentName: "",
            studentPhone: "",
            studentGender: "",
            studentAddress: "",
            educationalLevel: "",
            studentStatus: "",
            username: "",
            password: "",
          })
          notify(message, "success")
          setIsOpen(false)
        } else {
          notify(message, "error")
          setdisabled(false)
          setload(false)
        }
      }
    } catch (error) {
      console.log(error.response)
      notify(error.message, "error")
      setdisabled(false)
      setload(false)
    }
  }

  const handleChange = e => {
    setstudentObj(prev => {
      return { ...prev, [e.target.name]: e.target.value }
    })
  }
  const handleEdit = async transData => {
    let data = { ...transData }
    data.courseID = data.courseID?._id
    data.teacherID = data.teacherID?._id

    data.startedDate = moment(data.startedDate).format("YYYY-MM-DD")
    data.endDate = moment(data.endDate).format("YYYY-MM-DD")
    data.date = new Date(data.date)
    setEditCondition(true)
    setstudentObj(data)
    setIsOpen(true)
  }
  const handleshow = () => {
    if (showpassword) {
      setshowicon("mdi mdi-eye")
    } else {
      setshowicon("mdi mdi-eye-off")
    }
    setshowpassword(!showpassword)
  }
  return (
    <studentsContext.Provider
      value={{
        handleEdit,
        handleChange,
        HandleSubmitBtn,
        tog_standard,
        isOpen,
        setIsOpen,
        studentData,
        editCondition,
        studentObj,
        disabled,
        load,
        handleshow,
        showpassword,
        showicon,
      }}
    >
      {props.children}
    </studentsContext.Provider>
  )
}

export default StudentsContextProvider
