import { MDBDataTable } from "mdbreact"
import React, { useEffect, useState } from "react"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
// import axios from "axios"
import { AvField, AvForm } from "availity-reactstrap-validation"
import moment from "moment"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap"
// import { FetchTrensections } from "./TransectionApi";
import ReactEcharts from "echarts-for-react"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
//Import Components
import axios from "axios"
import jwtDecode from "jwt-decode"
import SweetAlert from "react-bootstrap-sweetalert"
import { useLocation } from "react-router-dom"
import Select from "react-select"
import useSwr, { mutate } from "swr"
import Auhtentication from "../../authMidlleware/userMiddleWare"
import {
  GetAll,
  deletefunc,
  postfunc,
  putfunc,
} from "../../services/mainService"
const Perfomance = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [editCondition, setEditCondition] = useState(false)
  const [customerObj, setcustomerObj] = useState({
    employeeID: "",
    taskDate: "",
    description: "",
    commission: "",
    taskPrice: "",
    quantity: "",
    taskType: "",
  })
  const [disabled, setdisabled] = useState(false)
  const [load, setload] = useState(false)
  const [employee, setemployee] = useState([])
  const [selectedEmployee, setselectedEmployee] = useState({})
  const [currentUser, setcurrentUser] = useState({})
  const [deletedr, setdeletedr] = useState([])
  const [deletealert, setdeletealert] = useState(false)
  const [chartsummary, setchartsummary] = useState({
    totalRequired: 34,
    currenTasks: 0,
  })

  const token = localStorage.getItem("token")
  const headers = {
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
  }
  let epiEndPoint = process.env.REACT_APP_ENDPOINT

  useEffect(() => {
    async function onload() {
      try {
        const { data: employeeList } = await GetAll("employee", headers)

        let checkemployee = Array.isArray(employeeList)
        if (checkemployee) {
          setemployee(employeeList)
        }

        const token = localStorage.getItem("token")
        if (token) {
          const user = jwtDecode(token)
          console.log("user", user)
          setcurrentUser(user)
        }
      } catch (error) {
        console.log(error.message)
      }
    }
    onload()
  }, [])

  async function fetchAll(path) {
    const { data } = await axios.get(epiEndPoint + "/" + path, headers)
    return data
  }

  const location = useLocation()
  Auhtentication(location.pathname)

  const { data: customerslist } = useSwr("performance", fetchAll)
  let customersData = []

  let chechArray = Array.isArray(customerslist)
  if (chechArray == true) {
    customersData = customerslist
  }
  const tog_standard = () => {
    setIsOpen(!isOpen)
    // setcustomerObj({
    //   date: new Date(),
    //   amount: "",
    //   description: "",
    // });
    setEditCondition(false)
    setselectedEmployee({})
    setcustomerObj({
      employeeID: "",
      taskDate: "",
      description: "",
      commission: "",
      taskPrice: "",
      quantity: "",
      taskType: "",
    })

    // setEditCondition(!editCondition);
  }
  const breadcrumbItems = [
    { title: "software", link: "#" },
    { title: "performance", link: "#" },
  ]
  const handleChange = e => {
    setcustomerObj(prev => {
      return { ...prev, [e.target.name]: e.target.value }
    })
  }
  const notify = (message, type) => toast[type](message)

  const HandleSubmitBtn = async e => {
    try {
      if (selectedEmployee.value == undefined) {
        return notify("pls select employee", "error")
      }

      setdisabled(true)
      setload(true)
      e.preventDefault()
      const token = localStorage.getItem("token")

      customerObj.employeeID = selectedEmployee.value

      if (editCondition) {
        const { data } = await putfunc("performance", customerObj, headers)
        console.log(data.info)
        const { status, message, info } = { ...data }
        if (status) {
          setdisabled(false)
          setload(false)
          mutate("performance", fetchAll)
          notify(message, "success")
          setIsOpen(false)
          setEditCondition(false)
        } else {
          setdisabled(false)
          setload(false)
          notify(message, "error")
        }
      } else {
        if (customerObj.description == "") {
          delete customerObj.description
        }
        //   return   console.log("customerObj",customerObj);
        const { data } = await postfunc("performance", customerObj, headers)
        const { status, message, info } = { ...data }
        if (status) {
          setdisabled(false)
          setload(false)
          setIsOpen(false)

          notify(message, "success")
          mutate("performance", fetchAll)
          setcustomerObj({
            employeeID: "",
            taskDate: "",
            description: "",
            commission: "",
            taskPrice: "",
            quantity: "",
            taskType: "",
          })
        } else {
          setdisabled(false)
          setload(false)
          notify(message, "error")
        }
      }
    } catch (error) {
      console.log(error.response)
      notify(error.message, "error")
      setdisabled(false)
      setload(false)
    }
  }

  const handleEdit = async transData => {
    let data = { ...transData }
    setselectedEmployee({
      label: data.employeeID?.name,
      value: data.employeeID?._id,
    })
    data.taskDate = moment(data.taskDate).format("YYYY-MM-DD[T]HH:mm:ss")
    setEditCondition(true)
    setcustomerObj(data)
    setIsOpen(true)
  }

  const UserData = {
    columns: [
      {
        label: "employee",
        field: "employeeName",
        sort: "asc",
        width: 40,
      },
      {
        label: "Task Type",
        field: "taskType",
        sort: "asc",
        width: 200,
      },
      {
        label: "Quantity",
        field: "quantity",
        sort: "asc",
        width: 60,
      },
      {
        label: "Price",
        field: "taskPrice",
        sort: "asc",
        width: 50,
      },
      {
        label: "Commission",
        field: "commission",
        sort: "asc",
        width: 50,
      },
      {
        label: "Toal Amount",
        field: "ToalAmount",
        sort: "asc",
        width: 50,
      },
      {
        label: "Description",
        field: "description",
        sort: "asc",
        width: 50,
      },
      {
        label: "Task Date",
        field: "taskDate",
        sort: "asc",
        width: 50,
      },
      {
        label: "Created Date",
        field: "date",
        sort: "asc",
        width: 270,
      },

      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 50,
      },
    ],
    rows: customersData?.map(cust => {
      const data = { ...cust }
      data.employeeName = data.employeeID?.name
      data.commission = data.commission
      data.ToalAmount = "$" + data.commission * data.taskPrice
      data.taskPrice = "$" + data.taskPrice
      // data.commission = "$" + data.commission
      data.date = moment(data.createdAt).format("lll")
      data.taskDate = moment(data.taskDate).format("lll")

      data.action = (
        <div>
          {currentUser.userType != "Marketing" ? (
            <>
              <button
                onClick={() => handleEdit(cust)}
                type="button"
                className="btn btn-white btn-sm mr-4"
              >
                <i
                  style={{
                    color: "#14213D",
                  }}
                  className="mdi mdi-pencil font-size-20 "
                ></i>
              </button>

              <button
                type="button"
                onClick={() => handleDelete(cust)}
                className="btn btn-white btn-sm"
              >
                <i className="mdi mdi-trash-can font-size-20 text-danger "></i>
              </button>
              <button
                type="button"
                onClick={() => handleDelete(cust)}
                className="btn btn-white btn-sm bg-primary font-size-20  text-white"
              >
                <i className="ion ion-logo-usd text-white me-2 "></i>
                Pay
              </button>
            </>
          ) : null}
        </div>
      )
      return data
    }),
  }
  const EmployeeIDOnachnge = e => {
    try {
      //   console.log(e)
      if (e.value) {
        setselectedEmployee(e)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const handledeletePerformance = async () => {
    // console.log("delte")
    try {
      // const { data } = await axios.delete(`${url}/suplier/${deletedr}`, headers)
      let obj = { _id: deletedr }
      const { data } = await deletefunc("performance", obj, headers)
      setdeletealert(false)
      if (data.status === true) {
        toast.success(data.message)
        mutate("performance", fetchAll)
      }
    } catch (error) {
      toast.error(error.message)
    }
  }
  const handleDelete = deletedata => {
    setdeletedr(deletedata._id)
    setdeletealert(true)
  }
  const getOption = () => {
    return {
      toolbox: {
        show: false,
      },
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b}: {c} ({d}%)",
      },
      legend: {
        orient: "vertical",
        x: "left",
        data: ["Total Tasks", "Current Tasks"],
        textStyle: {
          color: ["#74788d"],
        },
      },
      color: ["#02a499", "#F48500"],
      series: [
        {
          name: "Tasks",
          type: "pie",
          radius: ["50%", "70%"],
          avoidLabelOverlap: false,
          label: {
            normal: {
              show: false,
              position: "center",
            },
            emphasis: {
              show: true,
              textStyle: {
                fontSize: "10",
                fontWeight: "bold",
              },
            },
          },
          labelLine: {
            normal: {
              show: false,
            },
          },
          data: [
            // { value: chartsummary.totalRequired, name: "Total  Tasks" },
            // { value: chartsummary.upsent, name: "Current Tasks" },

            { value: customersData.length, name: "Current Tasks" },
            { value: chartsummary.totalRequired, name: "Total Tasks" },
          ],
        },
      ],
    }
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="performance" breadcrumbItems={breadcrumbItems} />
          <Row>
            <Col sm={6} md={4} xl={3} className="mt-4">
              <div className="text-center"></div>
              <Modal isOpen={isOpen} toggle={tog_standard}>
                <ModalHeader
                  style={{
                    background: "#14213D",
                    color: "white",
                    border: "#0A928C",
                  }}
                  toggle={tog_standard}
                >
                  {" "}
                  <p>Add Performance</p>
                </ModalHeader>
                <AvForm
                  className="needs-validation"
                  onValidSubmit={HandleSubmitBtn}
                >
                  <ModalBody>
                    <Row>
                      <Col>
                        <FormGroup className="mt-3">
                          <Label>Select Employee</Label>
                          <Select
                            name="employeeID"
                            value={selectedEmployee}
                            // isClearable={true}
                            isSearchable={true}
                            onChange={e => EmployeeIDOnachnge(e)}
                            options={employee?.map((employe, index) => {
                              return {
                                label: employe.name,
                                value: employe._id,
                                key: index,
                              }
                            })}
                          ></Select>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className="mt-3">
                      <Col>
                        <FormGroup>
                          <AvField
                            name="taskType"
                            label="Task Type"
                            placeholder="Enter task type"
                            type="text"
                            value={customerObj.taskType}
                            onChange={e => handleChange(e)}
                            className="form-control"
                            errorMessage="Enter task type"
                            validate={{
                              required: { value: true },
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <AvField
                            type="number"
                            name="quantity"
                            label="quantity"
                            value={customerObj.quantity}
                            onChange={e => handleChange(e)}
                            placeholder="Eneter quantity"
                            errorMessage="Enter quantity"
                            validate={{
                              required: { value: true },
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col>
                        <FormGroup>
                          <AvField
                            type="number"
                            name="taskPrice"
                            label="Price"
                            value={customerObj.taskPrice}
                            onChange={e => handleChange(e)}
                            placeholder="Enter Price"
                            errorMessage="Enter Price"
                            validate={{
                              required: { value: true },
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <AvField
                            type="number"
                            name="commission"
                            label="commission"
                            value={customerObj.commission}
                            onChange={e => handleChange(e)}
                            placeholder="Eneter commission"
                            errorMessage="Enter commission"
                            validate={{
                              required: { value: true },
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className="mt-3">
                      <Col>
                        <FormGroup>
                          <AvField
                            name="taskDate"
                            type="datetime-local"
                            label="task Date"
                            value={customerObj.taskDate}
                            onChange={e => handleChange(e)}
                            placeholder="Enter task date "
                            errorMessage="Enter task date"
                            validate={{
                              required: { value: true },
                            }}
                          ></AvField>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col>
                        <FormGroup>
                          <AvField
                            type="textarea"
                            label="Description (Optinal)"
                            name="description"
                            value={customerObj.description}
                            onChange={e => handleChange(e)}
                            placeholder="Enter Description"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      type="button"
                      color="danger"
                      onClick={tog_standard}
                      className="waves-effect"
                    >
                      Close
                    </Button>

                    <Button
                      type="submit"
                      style={{
                        background: "#14213D",
                        color: "white",
                      }}
                      disabled={disabled}
                      className="waves-effect waves-light"
                    >
                      {load ? "saving..." : " Save performance"}
                    </Button>
                  </ModalFooter>
                </AvForm>
              </Modal>
            </Col>
          </Row>
          <ToastContainer />

          {/* data table Row  */}
          <Row>
            <Col xs={12}>
              <Card>
                {/* <CardHeader  style={{background:"#14213D",color:"white"}}>
                 Here you can register your customer and also get's customers record.
                </CardHeader> */}
                <CardBody>
                  <Row className="mb-2">
                    <Col sm={6} md={8} xl={10}>
                      <h4 className="card-title">Performance Table </h4>
                    </Col>
                    <Col sm={6} md={4} xl={2}>
                      {currentUser.userType !== "Marketing" ? (
                        <Button
                          type="button"
                          onClick={tog_standard}
                          style={{ background: "#14213D", color: "white" }}
                          className="waves-effect waves-light float-right"
                        >
                          <i className="ri-add-line"></i> Add Performance
                        </Button>
                      ) : null}
                    </Col>
                  </Row>
                  {currentUser.userType == "Marketing" ? (
                    <Row className="">
                      <Col md={4}>
                        <p>
                          <b style={{ fontWeight: 600 }}>Total Tasks : </b>
                          <span className="font-size-30">
                            {chartsummary.totalRequired}
                          </span>
                        </p>
                      </Col>
                      <Col md={4}>
                        <p>
                          <b style={{ fontWeight: 600 }}>
                            Your Current Tasks:{" "}
                          </b>
                          <span id="lbl_class">{customersData.length}</span>
                        </p>
                      </Col>
                      {/* <Col md={4}>
                        <p>
                          <b style={{ fontWeight: 600 }}>upsent periods: </b>
                          <span id="lbl_class">{chartsummary.upsent}</span>
                        </p>
                      </Col> */}
                      <ReactEcharts
                        style={{ height: "100px" }}
                        option={getOption()}
                      />
                    </Row>
                  ) : null}

                  <MDBDataTable responsive striped bordered data={UserData} />
                </CardBody>
              </Card>
            </Col>
          </Row>
          {deletealert ? (
            <SweetAlert
              title="Are you sure?"
              warning
              showCancel
              confirmButtonText="Yes, delete it!"
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              onConfirm={handledeletePerformance}
              onCancel={() => setdeletealert(false)}
            >
              Are You sure to delete this performance!
            </SweetAlert>
          ) : null}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Perfomance
