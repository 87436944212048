import React from "react"
import MetaTags from "react-meta-tags"
import { Container } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import ReceiptContextProvider from "../../context/receiptContext"
import "../Dashboard/datatables.scss"
import ReceiptForm from "./receiptForm"
import ReceiptTable from "./receiptTable"

const Receipt = () => {
  return (
    <React.Fragment>
      <ReceiptContextProvider>
        <div className="page-content">
          <MetaTags>
            <title>Receipt | Human Diasgnostic Center</title>
          </MetaTags>
          <Container fluid>
            <div className="page-title-box">
              <Breadcrumbs
                maintitle="Veltrix"
                title="Receipt"
                breadcrumbItem="receipt"
              />
              <ReceiptForm />
              <ReceiptTable />
            </div>
          </Container>
        </div>
      </ReceiptContextProvider>
    </React.Fragment>
  )
}

export default Receipt
