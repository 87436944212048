import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Link, useHistory } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Row,
} from "reactstrap"
// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation"

// action
import { apiError, registerUser, registerUserFailed } from "../../store/actions"

// Redux
import jwtDecode from "jwt-decode"
import { connect } from "react-redux"
import { GetAll } from "../../services/mainService"
// import images
import fullLogo from "../../assets/images/hdclogo.png"
// import { toast } from "react-toastify"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
const Register = props => {
  const [menu, setMenu] = useState(false)
  const [username, setusername] = useState("Admin")

  const [currentStudent, setcurrentStudent] = useState({})
  const [studentclasses, setstudentclasses] = useState([])
  const [marks, setmarks] = useState([])
  const [tempMarks, settempMarks] = useState([])

  const [subjects, setsubjects] = useState([])
  const [summary, setsummary] = useState({
    total: 0,
    average: "",
    Grade: "",
    status: "",
    classPosittion: 0,
  })

  const token = localStorage.getItem("studentToken")
  const headers = {
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
  }
  function getGrade(currentaverage) {
    if (currentaverage > 94 && currentaverage <= 100) {
      return "A+"
    } else if (currentaverage > 89 && currentaverage <= 95) {
      return "A"
    } else if (currentaverage > 84 && currentaverage < 90) {
      return "B+"
    } else if (currentaverage > 79 && currentaverage < 85) {
      return "B"
    } else if (currentaverage > 49 && currentaverage < 80) {
      return "C"
    } else {
      return "F"
    }
  }
  useEffect(() => {
    async function load() {
      const studentToken = localStorage.getItem("studentToken")
      if (studentToken) {
        let student = jwtDecode(studentToken)

        setcurrentStudent(student.studentInfo)
        setusername(student.name)
        setstudentclasses(student.studentClasses)
        let { data } = await GetAll("marks", headers)
        // let studetnMarks=data?.filter(mark=>mark.studentID._id==student.studentID )

        if (data) {
          settempMarks(data)
        }
        let { data: subjects } = await GetAll("subject", headers)
        if (subjects) {
          setsubjects(subjects)
        }
      }
    }
    load()
  }, [])

  const handlechnage = e => {
    try {
      if (e.target.value == undefined || e.target.value == "") {
        toast.error("Class Not Found", {
          theme: "colored",
        })
        return
      }

      let studetnClassMarks = tempMarks?.filter(
        mark =>
          mark.studentID?._id == currentStudent._id &&
          mark.classID._id == e.target.value
      )

      if (studetnClassMarks.length == 0) {
        toast.info("No Marks Found", {
          theme: "colored",
        })
        return
      }

      let getCurrentClassObj = studentclasses.find(
        curentClass => curentClass?._id == e.target.value
      )

      let Coursesubjects = subjects.filter(
        courseObj => courseObj.courseID?._id == getCurrentClassObj?.courseID
      )
      // console.log("Coursesubjects", Coursesubjects)

      setmarks(studetnClassMarks)
      // console.log("studetnClassMarks", studetnClassMarks)
      // console.log("subjects", subjects)
      const total = studetnClassMarks.reduce(
        (total, item) => total + item.Amount,
        0
      )

      let average = total / studetnClassMarks.length
      let Grade = getGrade(average.toFixed(2))
      let status = average < 50 ? "Fail" : "Pass"

      setsummary({
        total: total.toFixed(2),
        average: average.toFixed(2),
        Grade: Grade,
        status: status,
        classPosittion: 0,
      })
    } catch (error) {
      toast.error(error.message, {
        theme: "colored",
      })
    }
  }
  const studentToken = localStorage.getItem("studentToken")
  const studentTokenSession = sessionStorage.getItem("studentToken")
  const history = useHistory()
  if (!studentTokenSession) {
    history.push(`/loginportal`)
  }
  const handleLogOut = () => {
    sessionStorage.removeItem("studentToken")
    localStorage.removeItem("studentToken")
    history.push(`/loginportal`)
  }

  const hanldeRedirect = () => {
    window.open("http://zaki.ezyro.com/?i=1", "_blank")
  }
  return (
    <React.Fragment>
      <MetaTags>
        <title>portal | Human Diasgnostic Center</title>
      </MetaTags>
      <div style={{ backgroundColor: "#D8E9F9" }}>
        <ToastContainer />
        <div className="container">
          <Row>
            <Col className="mt-3 col-md-9">
              <img src={fullLogo} height="48" width={170} alt="logo" />
            </Col>
            <Col className="mt-3 col-md-3 " color="">
              {/* <Button onClick={()=>handleLogOut()} style={{backgroundColor:"#E27356"}} className="  text-white float-right">
                <i className="ion ion-md-log-out"></i> Logout
              </Button> */}
              <Dropdown
                isOpen={menu}
                toggle={() => setMenu(!menu)}
                className="d-inline-block"
              >
                <DropdownToggle
                  className="btn header-item waves-effect pl-4"
                  id="page-header-user-dropdown"
                  tag="button"
                >
                  {/* <img
            className="rounded-circle header-profile-user pr-4"
            src={profileImage}
            alt="Header Avatar"
          />   */}
                  <span style={{ color: "#14213D" }}>{username}</span>
                  <span
                    style={{ color: "#14213D" }}
                    className="mdi mdi-chevron-down "
                  ></span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end ">
                  <Link to="/portal" className="dropdown-item">
                    <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
                    <span>viewMarks</span>
                  </Link>

                  <Link to="/viewAttendence" className="dropdown-item">
                    <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
                    <span>viewAttendence</span>
                  </Link>

                  <div className="dropdown-divider" />
                  <Button
                    onClick={() => handleLogOut()}
                    className="dropdown-item"
                  >
                    <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
                    <span>Logout</span>
                  </Button>
                </DropdownMenu>
              </Dropdown>
            </Col>
          </Row>
        </div>
      </div>

      <div className="account-pages py-2 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={12} lg={12} xl={12}>
              <Card className="overflow-hidden">
                <div style={{ backgroundColor: "#14213D" }}>
                  <div className="text-primary text-center p-4">
                    <h5 className="text-white font-size-20">
                      Student Information
                    </h5>
                    <p className="text-white-50">Get your Result now.</p>
                    {/* <a href="index.html" className="logo logo-admin">
                      <img src={logoSm} height="24" alt="logo" />
                    </a> */}
                  </div>
                </div>
                <CardBody className="p-4">
                  <div
                    class="row"
                    style={{ padding: "10px" }}
                    bis_skin_checked="1"
                  >
                    <div class="col-md-4" bis_skin_checked="1">
                      <p>
                        <b style={{ fontWeight: 600 }}> Name: </b>
                        <span id="lbl_name">{currentStudent?.studentName}</span>
                      </p>
                      <p>
                        <b style={{ fontWeight: 600 }}> Student ID: </b>
                        <span id="lbl_student_id">{currentStudent?.stdID}</span>
                      </p>
                      <p>
                        <b style={{ fontWeight: 600 }}>Phone: </b>
                        <span id="lbl_faculty">
                          {currentStudent?.studentPhone}
                        </span>
                      </p>
                      <p>
                        <b style={{ fontWeight: 600 }}>Address: </b>
                        <span id="lbl_department">
                          {currentStudent?.studentAddress}
                        </span>
                      </p>
                      <p>
                        <b style={{ fontWeight: 600 }}>Education Level: </b>
                        <span id="lbl_class">
                          {currentStudent?.educationalLevel}
                        </span>
                      </p>
                      {/* <p>
                        <b style={{ fontWeight: 600 }}>Current class: </b>
                        <span id="lbl_current_semester">8</span>
                      </p> */}
                    </div>

                    <div class="col-md-2" bis_skin_checked="1">
                      <p>
                        <b style={{ fontWeight: 500 }}>Total Marks: </b>

                        <b style={{ fontWeight: 600 }} id="lbl_total">
                          {summary.total}
                        </b>
                      </p>
                    </div>
                    <div class="col-md-2" bis_skin_checked="1">
                      <p>
                        <b style={{ fontWeight: 500 }}>Average: </b>

                        <b style={{ fontWeight: 600 }} id="lbl_total">
                          {summary.average}
                        </b>
                      </p>
                    </div>
                    <div class="col-md-2" bis_skin_checked="1">
                      <p>
                        <b style={{ fontWeight: 500 }}>Grade: </b>

                        <b style={{ fontWeight: 600 }} id="lbl_total">
                          {summary.Grade}
                        </b>
                      </p>
                    </div>
                    <div class="col-md-2" bis_skin_checked="1">
                      <p>
                        <b style={{ fontWeight: 500 }}> </b>

                        <b
                          style={{ fontWeight: 800 }}
                          className={
                            summary.status == "Pass"
                              ? "text-success"
                              : "text-danger"
                          }
                          id="lbl_total"
                        >
                          {summary.status}
                        </b>
                      </p>
                    </div>
                  </div>
                  <hr></hr>

                  <AvForm>
                    <div class="row" bis_skin_checked="1">
                      <div
                        class="col-lg-3 col-md-4 col-sm-6 col-xs-7"
                        bis_skin_checked="1"
                      >
                        <AvField
                          type="select"
                          class="form-control"
                          name="selectedclass"
                          onChange={e => handlechnage(e)}
                        >
                          <option value=""> select class</option>
                          {studentclasses?.map(stdclas => {
                            return (
                              <option value={stdclas._id}>
                                {stdclas.className}
                              </option>
                            )
                          })}
                        </AvField>
                      </div>

                      <div
                        class="col-lg-3 col-md-4 col-sm-4 col-xs-5"
                        bis_skin_checked="1"
                      >
                        <button id="print" class="btn btn-light">
                          <i class="fa fa-print"></i>
                          Print
                        </button>
                      </div>
                    </div>
                  </AvForm>

                  <div class="table-responsive" bis_skin_checked="1">
                    <table
                      id="tblSemesterWise"
                      class="table"
                      width="100%"
                      cellpadding="5px"
                      style={{ borderCollapse: "collapse" }}
                    >
                      <thead>
                        <tr>
                          <th>class name</th>
                          <th>Subject Name</th>
                          {/* <th>CW1</th> */}

                          <th>Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {marks?.map(mark => {
                          return (
                            <tr>
                              <td>{mark.classID?.className}</td>
                              <td>{mark.subjectID?.subjectName}</td>
                              <td>{mark.Amount.toFixed(2)}</td>
                            </tr>
                          )
                        })}
                        {/* <td>CA411</td>
                          <td>10.00</td>
                          <td>97.00</td> */}
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Developed by 🙋‍♂️
                  <Link onClick={hanldeRedirect}> Eng zaki ahmed</Link>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Register.propTypes = {
  registerUser: PropTypes.func,
  registerUserFailed: PropTypes.func,
  registrationError: PropTypes.any,
  user: PropTypes.any,
}

const mapStatetoProps = state => {
  const { user, registrationError, loading } = state.Account
  return { user, registrationError, loading }
}

export default connect(mapStatetoProps, {
  registerUser,
  apiError,
  registerUserFailed,
})(Register)
