import { AvField, AvForm } from "availity-reactstrap-validation"
import { MDBDataTable } from "mdbreact"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { CSVLink } from "react-csv"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { GetAll } from "../../services/mainService"

const ReceiptReport = () => {
  const [receiptObj, setreceiptObj] = useState({
    startDate: "",
    endDate: "",
  })
  const [editCondition, setEditCondition] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  const [receiptData, setreceiptData] = useState([])
  const [receiptTemp, setreceiptTemp] = useState([])
  const token = localStorage.getItem("token")
  const headers = {
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
  }
  useEffect(() => {
    const getReceipt = async () => {
      try {
        const { data: receiptList } = await GetAll("receipt", headers)
        let checkReceipt = Array.isArray(receiptList)
        if (checkReceipt) {
          setreceiptTemp(receiptList)
          setreceiptData(receiptList)
        }
      } catch (error) {
        console.log(error.message)
      }
    }
    getReceipt()
  }, [])

  const notify = (message, type) => toast[type](message)

  const HandleSubmitBtn = async e => {
    try {
      let start = moment(receiptObj.startDate).format("YYYY-MM-DD")
      let end = moment(receiptObj.endDate).format("YYYY-MM-DD")

      let result = receiptTemp?.filter(item => {
        let date = moment(item.createdAt).format("YYYY-MM-DD")
        return date >= start && date <= end
      })
      setreceiptData(result)
    } catch (error) {
      console.log(error.response)
    }
  }
  const handleChange = e => {
    setreceiptObj(prev => {
      return { ...prev, [e.target.name]: e.target.value }
    })
  }
  const exportData = receiptData?.map(cust => {
    const data = { ...cust }

    data.studentName = data.studentID?.studentName
    data.stdID = data.studentID?.stdID
    data.userRecipted = data.userID?.name
    data.feeName = data.feeID?.feeName
    data.receiptAmount = "$" + data.AmountPaid
    data.date = moment(data.datePaid).format("lll")

    const obj = {
      No: data.receiptNo,
      "student Name": data.studentName,
      stdID: data.stdID,
      "fee Name": data.feeName,
      "phone Paid": data.phonePaid,
      "phone Receipt": data.phoneReceipt,
      AmountPaid: "$" + data.AmountPaid,
      "user Recipted": data.userRecipted,
      date: data.date,
    }

    return obj
  })

  const data = {
    columns: [
      {
        label: "No",
        field: "receiptNo",
        sort: "asc",
        width: 200,
      },

      {
        label: "student",
        field: "studentName",
        sort: "asc",
        width: 270,
      },
      {
        label: "fee Name",
        field: "feeName",
        sort: "asc",
        width: 150,
      },

      {
        label: "phone Paid",
        field: "phonePaid",
        sort: "asc",
        width: 100,
      },
      {
        label: "phone Receipt",
        field: "phoneReceipt",
        sort: "asc",
        width: 100,
      },
      {
        label: "receipt Amount",
        field: "receiptAmount",
        sort: "asc",
        width: 100,
      },

      {
        label: "userReceipt",
        field: "userRecipted",
        sort: "asc",
        width: 100,
      },

      {
        label: "datePaid",
        field: "date",
        sort: "asc",
        width: 100,
      },
    ],
    rows: receiptData?.map(hos => {
      const data = { ...hos }
      // data.studentName = data.studentID?.studentName;
      data.studentName =
        data.studentID?.studentName + " -" + data.studentID?.stdID
      data.userRecipted = data.userID?.name
      data.feeName = data.feeID?.feeName
      data.receiptAmount = "$" + data.AmountPaid
      data.date = moment(data.datePaid).format("lll")

      return data
    }),
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
            <Breadcrumbs
              maintitle="Human Diasgnostic Center"
              title="class"
              breadcrumbItem="class"
            />
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <AvForm
                      className="needs-validation"
                      onValidSubmit={HandleSubmitBtn}
                    >
                      <Row className=" mb-3">
                        <Col md={4}>
                          <div className="mb-3">
                            <FormGroup className="form-group">
                              <AvField
                                type="date"
                                name="startDate"
                                label="date started"
                                value={receiptObj.startDate}
                                onChange={e => handleChange(e)}
                                className="form-control"
                                errorMessage="please select start date"
                                validate={{ required: { value: true } }}
                              ></AvField>
                            </FormGroup>
                          </div>
                        </Col>

                        <Col md={4}>
                          <div className="mb-3">
                            <FormGroup className="form-group">
                              <AvField
                                type="date"
                                name="endDate"
                                label=" Ended Date "
                                value={receiptObj.endDate}
                                onChange={e => handleChange(e)}
                                className="form-control"
                                errorMessage="please end Date "
                                validate={{ required: { value: true } }}
                              ></AvField>
                            </FormGroup>
                          </div>
                        </Col>
                        <Col md={2}>
                          <div className="mb-3">
                            <Label>check out</Label>
                            <FormGroup className="form-group">
                              <Button
                                type="submit"
                                style={{
                                  background: "#0A928C",
                                  color: "white",
                                }}
                                className=" dropdown-toggle waves-effect waves-light "
                              >
                                <i className="ion ion-md-add me-2"></i> Check
                                Receipt
                              </Button>
                            </FormGroup>
                          </div>
                        </Col>
                        <Col md={2}>
                          <div className="mb-3">
                            <Label>Download List</Label>
                            <FormGroup className="form-group">
                              <CSVLink
                                filename={"TableContent.csv"}
                                data={exportData}
                                className="btn btn-success"
                              >
                                Download Excel
                              </CSVLink>
                            </FormGroup>
                          </div>
                        </Col>
                      </Row>
                    </AvForm>

                    <Row>
                      <MDBDataTable responsive striped bordered data={data} />
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ReceiptReport
