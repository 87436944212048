import { MDBDataTable } from "mdbreact"
import moment from "moment"
import { useContext } from "react"
import { Button, Card, CardBody, CardTitle, Col, Row } from "reactstrap"
import { classContext } from "../../context/classContext"
import "../Dashboard/datatables.scss"

import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
const classTable = () => {
  const { isOpen, editCondition, classesData, tog_standard, handleEdit } =
    useContext(classContext)

  const data = {
    columns: [
      {
        label: "Name",
        field: "className",
        sort: "asc",
        width: 150,
      },
      {
        label: "course",
        field: "courseName",
        sort: "asc",
        width: 270,
      },
      {
        label: "teacher",
        field: "teacherName",
        sort: "asc",
        width: 200,
      },
      {
        label: "Days",
        field: "days",
        sort: "asc",
        width: 100,
      },
      {
        label: "classShift",
        field: "classShift",
        sort: "asc",
        width: 100,
      },
      {
        label: "StartDate",
        field: "startedDate1",
        sort: "asc",
        width: 100,
      },
      {
        label: "EndedDate",
        field: "endedDate1",
        sort: "asc",
        width: 100,
      },

      {
        label: "TimeIn",
        field: "TimeIn",
        sort: "asc",
        width: 100,
      },
      {
        label: "TimeOut",
        field: "TimeOut",
        sort: "asc",
        width: 100,
      },

      {
        label: "Status",
        field: "classStatus",
        sort: "asc",
        width: 100,
      },

      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 100,
      },
    ],
    rows: classesData?.map(hos => {
      const data = { ...hos }
      // data.hospitalName = data.hospitalID?.name;
      data.courseName = data.courseID?.courseName
      data.teacherName = data.teacherID?.teacherName
      data.startedDate1 = moment(data.startedDate).format("ll")
      data.endedDate1 = moment(data.endDate).format("ll")
      // data.days=data.days[0]+data.days[1]

      data.days = data.days?.map(d => d.value + ",")
      // data.timein = moment(data.TimeIn).format("LT")
      // data.timeout=moment(data.TimeOut).format("LT");
      // data.timein=moment(data.TimeIn, "HH:mm:ss").format("hh:mm A");
      // data.timeout=moment(data.TimeOut).subtract('days').format('h:mm:ss a')

      data.action = (
        <div>
          <button
            onClick={() => handleEdit(hos)}
            type="button"
            className="btn btn-white btn-sm mr-4"
          >
            <i
              style={{
                color: "#14213D",
              }}
              className="mdi mdi-pencil font-size-20 "
            ></i>
          </button>
          {/* <Dropdown
            isOpen={setting_Menu}
            toggle={() => {
              setsetting_Menu(!setting_Menu)
            }}
          >
            <DropdownToggle  className="dropdown-toggle waves-effect waves-light">
              <i className="ti-more"></i>
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem tag="a" href="#">Action</DropdownItem>
              <DropdownItem tag="a" href="#">Another action</DropdownItem>
              <DropdownItem tag="a" href="#">Something else here</DropdownItem>
              <DropdownItem divider />
              <DropdownItem tag="a" href="#">Separated link</DropdownItem>
            </DropdownMenu>
          </Dropdown> */}
        </div>
      )
      return data
    }),
  }
  return (
    <Row>
      <Col className="col-12">
        <Card>
          <CardBody>
            <Row className="align-items-center  mb-3">
              <Col md={8}>
                <CardTitle className="h4">class Table </CardTitle>
              </Col>

              <Col md="4">
                <div className="float-end d-none d-md-block">
                  <Button
                    onClick={tog_standard}
                    style={{
                      background: "#14213D",
                      color: "white",
                      border: "#0A928C",
                    }}
                    className=" dropdown-toggle waves-effect waves-light"
                  >
                    <i className="ion ion-md-add me-2"></i> Add Class
                  </Button>
                </div>
              </Col>
            </Row>
            <ToastContainer />

            <MDBDataTable responsive striped bordered data={data} />
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default classTable
