import React, { createContext, useState, useEffect } from "react"
import {
  Col,
  Container,
  Button,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  ModalFooter,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap"
import moment from "moment"
import ReactEcharts from 'echarts-for-react';
import { GetAll, fetchAll, postfunc, putfunc } from "../../services/mainService"
import { MDBDataTable } from "mdbreact"
import useSwr, { mutate, trigger, useSWRConfig } from "swr"
import { ToastContainer, toast } from "react-toastify"
import { CSVLink } from "react-csv"
import "react-toastify/dist/ReactToastify.css"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { AvForm, AvField } from "availity-reactstrap-validation"

const AttendenceReport = () => {
  const [attendenceObj, setattendenceObj] = useState({
    classID: "",
    status: "",
    studentID: "All",
  })

  const [studentEnrolledData, setstudentEnrolledData] = useState([])
  const [classesData, setclassesData] = useState([])
  const [classesTemp, setclassesTemp] = useState([])
  const [attendenceData, setattendenceData] = useState([])
  const [attendenceTemp, setattendenceTemp] = useState([])
  const [studentsData, setstudentsData] = useState([])
const [chartsummary, setchartsummary] = useState({
  totalAttended:0,
  upsent:0,
  present:0
})
const token = localStorage.getItem("token");
const headers = {
  headers: {
    "Content-Type": "application/json",
    "auth-token": token
  },
};

useEffect(() => {
  const getfee = async () => {
    try {
      const { data: studentEnrollmentList } = await GetAll("enrollment",headers)
      const { data: classList } = await GetAll("class",headers)
      const { data: attendenceList } = await GetAll("attendence",headers)
      const { data: student } = await GetAll("student",headers)
      let checkenrollment=Array.isArray(studentEnrollmentList)
      let checkclass=Array.isArray(classList)
      let checkattendence=Array.isArray(attendenceList)
      let checkstudent=Array.isArray(student)

      if(checkenrollment){
        setstudentEnrolledData(studentEnrollmentList)
      }
      if(checkclass){
        setclassesData(classList)
        setclassesTemp(classList)
      }
      if(checkattendence){
        setattendenceTemp(attendenceList)
        setattendenceData(attendenceList) 
      }
      if(checkstudent){
        setstudentsData(student)
      }
    } catch (error) {
      console.log(error.message)
    }
  }
  getfee()
}, [])
  const notify = (message, type) => toast[type](message)

  const HandleSubmitBtn = async e => {
    try {
        console.log("attendenceObj",attendenceObj)
       
      if (attendenceObj.studentID == "All") {
        let classAttendence = attendenceTemp.filter( att =>
            att.classID?._id == attendenceObj.classID && att.attendenceStatus== attendenceObj.status
        )

        // let classAttende = attendenceTemp.filter( att =>
        //     att.classID?._id == attendenceObj.classID 
        // )
        // let totalAtt=classAttende.length

        // let totalpresent = classAttende.reduce(
        //   (total, item) => total + item.attended,
        //   0
        // );
        // let totalunpsent = totalAtt-totalpresent
        // console.log(totalAtt,totalpresent,totalunpsent)
        // setchartsummary({
        //   totalAttended:totalAtt,
        //   present:totalpresent,
        //   upsent:totalunpsent,
        // })
      
        setattendenceData(classAttendence)
        setchartsummary({
          totalAttended:0,
          present:0,
          upsent:0,
        })
      } else if(attendenceObj.studentID != "All"){
        let classAttendence = attendenceTemp.filter( att =>
            att.classID?._id == attendenceObj.classID && att.studentID?._id==attendenceObj.studentID && att.attendenceStatus== attendenceObj.status
        )
        let classAttende = attendenceTemp.filter( att =>
            att.classID?._id == attendenceObj.classID && att.studentID?._id==attendenceObj.studentID
        )
        console.log("classAttende",classAttende)
      
        setattendenceData(classAttendence)
        let totalAtt=classAttende.length

        let totalpresent = classAttende.reduce(
          (total, item) => total + item.attended,
          0
        );
        let totalunpsent = totalAtt-totalpresent
        // console.log(totalAtt,totalpresent,totalunpsent)
        setchartsummary({
          totalAttended:totalAtt,
          present:totalpresent,
          upsent:totalunpsent,
        })
      }
    } catch (error) {
      console.log(error.response)
    }
  }
  const handleChangestudent = e => {
    if (e.target.value !== "All") {
        let studentClass=studentEnrolledData.filter(clas => clas.studentID?._id === e.target.value)

        let filtered = [];
        classesTemp.filter(function(newData) {
            return studentClass.filter(function(oldData) {
                if (newData._id == oldData.classID?._id ) {
                filtered.push(newData)
              }
            })
          })
          setclassesData(filtered)

      setattendenceObj({
        classID: attendenceObj.classID,
        status: attendenceObj.status,
        studentID: e.target.value,
      })
    }else{
        setclassesData(classesTemp)
        setattendenceObj({
          classID: "",
          status:"",
          studentID: "All",
        })
        setchartsummary({
          totalAttended:0,
          present:0,
          upsent:0,
        })
    }
  }
  const handleChange = e => {
    setattendenceObj(prev => {
      return { ...prev, [e.target.name]: e.target.value }
    })
  }
  const exportData = attendenceData?.map(cust => {
    const data = { ...cust }
    data.studentName = data.studentID?.studentName
    data.stdID = data.studentID?.stdID
    data.courseName = data.courseID?.courseName
    data.className = data.classID?.className
    data.teacherName = data.teacherID?.teacherName
    data.username = data.userID?.name
    data.Date = moment(data.createdAt).format("lll")

    data.date = moment(data.createdAt).format("lll")
    const obj = {
      "student name": data.studentName,
      "student ID": data.stdID,
      courseName: data.courseName,
      className: data.className,

      "date Attended":data.date,
      Day: data.day,
      Attended: data.attended,
      Status: data.attendenceStatus,
      "Teacher Attended": data.teacherName,
    }

    return obj
  })
  const data = {
    columns: [
      {
        label: "student Name",
        field: "studentName",
        sort: "asc",
        width: 150,
      },

      {
        label: "studentID",
        field: "stdID",
        sort: "asc",
        width: 200,
      },
      {
        label: "class Name",
        field: "className",
        sort: "asc",
        width: 200,
      },

      {
        label: "Date Attended",
        field: "Date",
        sort: "asc",
        width: 100,
      },
      {
        label: "Day",
        field: "day",
        sort: "asc",
        width: 100,
      },

      {
        label: "attended",
        field: "attended",
        sort: "asc",
        width: 100,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 100,
      },
      {
        label: "user attended",
        field: "teacherName",
        sort: "asc",
        width: 100,
      },
    ],
    rows: attendenceData?.map(hos => {
      const data = { ...hos }

      data.studentName = data.studentID?.studentName
      data.stdID = data.studentID?.stdID
      data.courseName = data.courseID?.courseName
      data.className = data.classID?.className
      data.teacherName = data.teacherID?.teacherName
      data.username = data.userID?.name
      data.Date = moment(data.createdAt).format("lll")

      if (data.attendenceStatus == "present") {
        data.status = (
          <div className="badge bg-success  font-size-15 badge-pill mr-2">
            {data.attendenceStatus}
          </div>
        )
      } else {
        data.status = (
          <div className="badge bg-danger font-size-15 badge-pill mr-2">
            {data.attendenceStatus}
          </div>
        )
      }

      return data
    }),
  }

 const getOption = () => {
    return {

        toolbox: {
            show: false,
        },
        tooltip: {
            trigger: 'item',
            formatter: "{a} <br/>{b}: {c} ({d}%)"
        },
        legend: {
            orient: 'vertical',
            x: 'left',
            data: ['present', 'upsent', ],
            textStyle: {
                color: ['#74788d']
            }
        },
        color: ['#02a499', '#ec4561',],
        series: [
            {
                name: 'attendece',
                type: 'pie',
                radius: ['50%', '70%'],
                avoidLabelOverlap: false,
                label: {
                    normal: {
                        show: false,
                        position: 'center'
                    },
                    emphasis: {
                        show: true,
                        textStyle: {
                            fontSize: '10',
                            fontWeight: 'bold'
                        }
                    }
                },
                labelLine: {
                    normal: {
                        show: false
                    }
                },
                data: [
                 
                    { value:chartsummary.present, name: 'present' },
                    { value: chartsummary.upsent, name: 'upsent' },
                   
                ]
            }
        ]
    };
};
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
            <Breadcrumbs
              maintitle="Veltrix"
              title="class"
              breadcrumbItem="class"
            />
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <AvForm
                      className="needs-validation"
                      onValidSubmit={HandleSubmitBtn}
                    >
                      <Row className=" mb-3">
                        <Col md={3}>
                          <div className="mb-3">
                            <FormGroup className="form-group">
                              <AvField
                                type="select"
                                name="studentID"
                                label="Select student"
                                value={attendenceObj.studentID}
                                onChange={e => handleChangestudent(e)}
                                className="form-control"
                                //   errorMessage="please select class "
                                //   validate={{ required: { value: true } }}
                              >
                                {/* <option value="">Select Students</option> */}
                                <option value="All">All </option>
                                {studentsData?.map(student => (
                                  <option value={student?._id}>
                                    {student.stdID}
                                  </option>
                                ))}
                              </AvField>
                            </FormGroup>
                          </div>
                        </Col>

                        <Col md={3}>
                          <div className="mb-3">
                            <FormGroup className="form-group">
                              <AvField
                                type="select"
                                name="classID"
                                label="class"
                                value={attendenceObj.classID}
                                onChange={e => handleChange(e)}
                                className="form-control"
                                errorMessage="please select class "
                                validate={{ required: { value: true } }}
                              >
                                <option vale="">Select Class</option>
                                {classesData?.map(classes => (
                                  <option value={classes?._id}>
                                    {classes?.className}
                                  </option>
                                ))}
                              </AvField>
                            </FormGroup>
                          </div>
                        </Col>

                        <Col md={3}>
                          <div className="mb-3">
                            <FormGroup className="form-group">
                              <AvField
                                type="select"
                                name="status"
                                label="Attendence status"
                                value={attendenceObj.status}
                                onChange={e => handleChange(e)}
                                className="form-control"
                                errorMessage="please select fee status "
                                validate={{ required: { value: true } }}
                              >
                                <option value="">Select Status</option>
                                <option value="upsent">upsent</option>
                                <option value="present">present</option>
                              </AvField>
                            </FormGroup>
                          </div>
                        </Col>
                        <Col md={3}>
                          <div className="mb-3">
                            <Label>check out</Label>
                            <FormGroup className="form-group">
                              <Button
                                type="submit"
                                style={{
                                  background: "#0A928C",
                                  color: "white",
                                }}
                                className=" dropdown-toggle waves-effect waves-light "
                              >
                                <i className="ion ion-md-add me-2"></i> check
                                attendence
                              </Button>
                            </FormGroup>
                          </div>
                        </Col>
                      </Row>
                    </AvForm>
                    <Row>
                      <Col className="mb-3 col-3">
                        {/* <Label>Download List</Label> */}
                        <FormGroup className="form-group">
                          <CSVLink
                            filename={"TableContent.csv"}
                            data={exportData}
                            className="btn btn-success"
                          >
                            Download Excel
                          </CSVLink>
                        </FormGroup>
                      </Col>

                      <Col classname=" mb-3 col-9">

                      <Row className="">
                    <Col sm={4}>
                      <div className="">
                        <h5 className="mb-0 font-size-20">{chartsummary.totalAttended}</h5>
                        <p className="text-muted">total periods</p>
                      </div>
                    </Col>
                    <Col sm={4}>
                      <div className="">
                        <h5 className="mb-0 font-size-20">{chartsummary.present}</h5>
                        <p className="text-muted">Present periods</p>
                      </div>
                    </Col>
                    <Col sm={4}>
                      <div className="">
                        <h5 className="mb-0 font-size-20">{chartsummary.upsent}</h5>
                        <p className="text-muted">upsent periods</p>
                      </div>
                    </Col>
                     </Row>
                      </Col>
                     <ReactEcharts style={{ height: "100px" }} option={getOption()} />
                    </Row>

                    <Row>
                      <MDBDataTable responsive striped bordered data={data} />
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AttendenceReport
