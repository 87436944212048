import { MDBDataTable } from "mdbreact"
import React, { useEffect, useState } from "react"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
// import axios from "axios"
import { AvField, AvForm } from "availity-reactstrap-validation"
import moment from "moment"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap"
// import { FetchTrensections } from "./TransectionApi";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
//Import Components
import axios from "axios"
import jwtDecode from "jwt-decode"
import { useLocation } from "react-router-dom"
import useSwr, { mutate } from "swr"
import Auhtentication from "../../authMidlleware/userMiddleWare"
import { postfunc, putfunc } from "../../services/mainService"
const Tasks = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [editCondition, setEditCondition] = useState(false)
  const [serviceObj, setserviceObj] = useState({
    serviceName: "",
    servicePrice: "",
    customerID: "",
    employeeID: "",
    workType: "",
    quantity: "",
    workStatus: "",
    prductionStatus: "",
  })
  const [customers, setcustomers] = useState([])
  const [selectedCustomer, setselectedCustomer] = useState({})
  const [selectedEmployee, setselectedEmployee] = useState({})
  const [currentUser, setcurrentUser] = useState({})
  const [disabled, setdisabled] = useState(false)
  const [load, setload] = useState(false)
  const [showDesign, setshowDesign] = useState(false)
  const [showprint, setshowprint] = useState(false)

  const [employee, setemployee] = useState([])
  const token = localStorage.getItem("token")
  const headers = {
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
  }
  let epiEndPoint = process.env.REACT_APP_ENDPOINT

  useEffect(() => {
    async function onload() {
      const token = localStorage.getItem("token")
      if (token) {
        const user = jwtDecode(token)
        setcurrentUser(user)
        console.log("user", user)
        if (user.userType == "designer") {
          setshowDesign(true)
        }
        if (user.userType == "production") {
          setshowprint(true)
        }
        if (user.userType == "admin" || user.userType == "superAdmin") {
          setshowDesign(true)
          setshowprint(true)
        }
      }
    }
    onload()
  }, [])

  async function fetchAll(path) {
    const { data } = await axios.get(epiEndPoint + "/" + path, headers)
    return data
  }

  let serviceData = []
  const { data: servicelist } = useSwr("service", fetchAll)

  let chechArray = Array.isArray(servicelist)
  if (chechArray == true) {
    serviceData = servicelist
  }
  const tog_standard = () => {
    setIsOpen(!isOpen)
    setserviceObj({
      serviceName: "",
      servicePrice: "",
      customerID: "",
    })
    setselectedCustomer({})
    setselectedEmployee({})
    setEditCondition(false)
  }
  const breadcrumbItems = [
    { title: "minibank", link: "#" },
    { title: "customer", link: "#" },
  ]
  const handleChange = e => {
    setserviceObj(prev => {
      return { ...prev, [e.target.name]: e.target.value }
    })
  }
  const notify = (message, type) => toast[type](message)

  const HandleSubmitBtn = async e => {
    try {
      e.preventDefault()
      if (selectedEmployee.value == undefined) {
        return notify("pls select employee", "error")
      }

      setdisabled(true)
      setload(true)
      const token = localStorage.getItem("token")

      serviceObj.employeeID = selectedEmployee.value
      serviceObj.customerID = selectedCustomer.value
      //   return console.log("serviceObj", serviceObj)

      if (editCondition) {
        const { data } = await putfunc("service", serviceObj, headers)
        console.log(data.info)
        const { status, message, info } = { ...data }
        if (status) {
          setdisabled(false)
          setload(false)
          mutate("service", fetchAll)
          notify(message, "success")
          setIsOpen(false)
          setEditCondition(false)
          setselectedEmployee({})
        } else {
          setdisabled(false)
          setload(false)
          notify(message, "error")
        }
      } else {
        if (Object.keys(selectedCustomer).length === 0)
          return toast.warning("please choose a customer")

        serviceObj.customerID = selectedCustomer.value
        serviceObj.userID = currentUser._id

        //   return   console.log("serviceObj",serviceObj);
        const { data } = await postfunc("service", serviceObj, headers)
        const { status, message, info } = { ...data }
        if (status) {
          setdisabled(false)
          setload(false)
          setIsOpen(false)
          setselectedEmployee({})
          notify(message, "success")
          mutate("service", fetchAll)
          setserviceObj({
            date: new Date(),
            description: "",
            amount: "",
          })
        } else {
          notify(message, "error")
          setdisabled(false)
          setload(false)
        }
      }
    } catch (error) {
      console.log(error.response)
      notify(error.message, "error")
      setdisabled(false)
      setload(false)
    }
  }

  const handleEdit = async transData => {
    let data = { ...transData }
    console.log("data", data)
    setEditCondition(true)
    setserviceObj(data)
    setIsOpen(true)
    setselectedCustomer({
      label: data.customerID?.name + "-" + data.customerID.code,
      value: data.customerID?._id,
    })
    setselectedEmployee({
      label: data.employeeID?.name,
      value: data.employeeID?._id,
    })
  }
  // console.log("serviceData", serviceData)

  const UserData = {
    columns: [
      {
        label: "Customer Name",
        field: "customerName",
        sort: "asc",
        width: 200,
      },
      {
        label: "serviceName",
        field: "serviceName",
        sort: "asc",
        width: 40,
      },
      {
        label: "Emp Designed",
        field: "EmployeeName",
        sort: "asc",
        width: 200,
      },
      {
        label: "Emp Printed",
        field: "EmployeePrinted",
        sort: "asc",
        width: 200,
      },

      {
        label: "quantity",
        field: "quantity",
        sort: "asc",
        width: 60,
      },
      {
        label: "workType",
        field: "workType",
        sort: "asc",
        width: 60,
      },

      {
        label: "Design Status",
        field: "workStatus",
        sort: "asc",
        width: 80,
      },
      {
        label: "Print Status",
        field: "prductionStatus",
        sort: "asc",
        width: 80,
      },
      {
        label: "Created Date",
        field: "date",
        sort: "asc",
        width: 270,
      },

      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 50,
      },
    ],
    rows: serviceData?.map(cust => {
      const data = { ...cust }
      data.customerName = data.customerID?.name
      data.EmployeeName = data.employeeID?.name
      data.EmployeePrinted = data.ProductionEmployeeID?.name
      data.servicePrice = "$" + data.servicePrice
      data.AmountPaid = "$" + data.AmountPaid
      data.Balance = "$" + data.Balance

      data.date = moment(data.createdAt).format("lll")

      if (data.workStatus == "progress") {
        data.workStatus = (
          <div className="badge bg-warning  font-size-15 badge-pill mr-2">
            {data.workStatus}
          </div>
        )
      } else if (data.workStatus == "done") {
        data.workStatus = (
          <div className="badge bg-success font-size-15 badge-pill mr-2">
            {data.workStatus}
          </div>
        )
      } else if (data.workStatus == "assigned") {
        data.workStatus = (
          <div className="badge bg-info font-size-15 badge-pill mr-2">
            {data.workStatus}
          </div>
        )
      } else {
        data.workStatus = (
          <div className="badge bg-danger font-size-15 badge-pill mr-2">
            {data.workStatus}
          </div>
        )
      }

      if (data.prductionStatus == "progress") {
        data.prductionStatus = (
          <div className="badge bg-warning  font-size-15 badge-pill mr-2">
            {data.prductionStatus}
          </div>
        )
      } else if (data.prductionStatus == "done") {
        data.prductionStatus = (
          <div className="badge bg-success font-size-15 badge-pill mr-2">
            {data.prductionStatus}
          </div>
        )
      } else if (data.prductionStatus == "assigned") {
        data.prductionStatus = (
          <div className="badge bg-info font-size-15 badge-pill mr-2">
            {data.prductionStatus}
          </div>
        )
      } else {
        data.prductionStatus = (
          <div className="badge bg-danger font-size-15 badge-pill mr-2">
            {data.prductionStatus}
          </div>
        )
      }

      data.action = (
        <div>
          <button
            onClick={() => handleEdit(cust)}
            type="button"
            className="btn btn-white btn-sm mr-4"
          >
            <i
              style={{
                color: "#14213D",
              }}
              className="mdi mdi-pencil font-size-20 "
            ></i>
          </button>
          {/* <button
            type="button"
            onClick={() => handleDelete(cust)}
            className="btn btn-white btn-sm"
          >
            <i className="mdi mdi-trash-can font-size-20 text-danger "></i>
          </button> */}
        </div>
      )
      return data
    }),
  }
  const location = useLocation()
  Auhtentication(location.pathname)

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Service" breadcrumbItems={breadcrumbItems} />
          <Row>
            <Col sm={6} md={4} xl={3} className="mt-4">
              <div className="text-center"></div>
              <Modal isOpen={isOpen} toggle={tog_standard}>
                <ModalHeader
                  style={{
                    background: "#14213D",
                    color: "white",
                    border: "#0A928C",
                  }}
                  toggle={tog_standard}
                >
                  {" "}
                  <p>Tasks</p>
                </ModalHeader>
                <AvForm
                  className="needs-validation"
                  onValidSubmit={HandleSubmitBtn}
                >
                  <ModalBody>
                    {showDesign ? (
                      <Row className="mt-3">
                        <Col>
                          <FormGroup>
                            <AvField
                              type="select"
                              name="workStatus"
                              label="Design Status"
                              placeholder="Enter Design Status"
                              value={serviceObj.workStatus}
                              onChange={e => handleChange(e)}
                              errorMessage="enter  design status"
                              validate={{
                                required: { value: true },
                              }}
                            >
                              <option value="">Select Design Status</option>
                              <option value="assigned">assigned</option>
                              <option value="progress">progress</option>
                              <option value="done">done</option>
                              <option value="none">none</option>
                            </AvField>
                          </FormGroup>
                        </Col>
                      </Row>
                    ) : null}

                    {showprint ? (
                      <Row className="mt-3">
                        <Col>
                          <FormGroup>
                            <AvField
                              type="select"
                              name="prductionStatus"
                              label="prductionStatus"
                              placeholder="Enter print"
                              value={serviceObj.prductionStatus}
                              onChange={e => handleChange(e)}
                              errorMessage="enter  production status"
                              validate={{
                                required: { value: true },
                              }}
                            >
                              <option value="">Select production Status</option>
                              <option value="assigned">assigned</option>
                              <option value="progress">progress</option>
                              <option value="done">done</option>
                              <option value="none">none</option>
                            </AvField>
                          </FormGroup>
                        </Col>
                      </Row>
                    ) : null}
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      type="button"
                      color="danger"
                      onClick={tog_standard}
                      className="waves-effect"
                    >
                      Close
                    </Button>

                    <Button
                      type="submit"
                      style={{
                        background: "#14213D",
                        color: "white",
                      }}
                      disabled={disabled}
                      className="waves-effect waves-light"
                    >
                      {load ? "Saiving Task..." : "Submit"}
                    </Button>
                  </ModalFooter>
                </AvForm>
              </Modal>
            </Col>
          </Row>
          <ToastContainer />

          {/* data table Row  */}
          <Row>
            <Col xs={12}>
              <Card>
                {/* <CardHeader  style={{background:"#14213D",color:"white"}}>
                 Here you can register your customer and also get's customers record.
                </CardHeader> */}
                <CardBody>
                  <Row className="mb-2">
                    <Col sm={6} md={8} xl={10}>
                      <h4 className="card-title">Tasks Table </h4>
                    </Col>
                  </Row>
                  <MDBDataTable responsive striped bordered data={UserData} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Tasks
