import React from "react"
import MetaTags from "react-meta-tags"
import { Container } from "reactstrap"
import "../Dashboard/datatables.scss"
// import UserTable from "./usesTable"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import AttendenceContextProvider from "../../context/attendenceContext"
import AttendenceTable from "./attendenceTable"

const AttendenceListIndex = () => {
  return (
    <React.Fragment>
      <AttendenceContextProvider>
        <div className="page-content">
          <MetaTags>
            <title>Attendence | Human Diasgnostic Center</title>
          </MetaTags>
          <Container fluid>
            <div className="page-title-box">
              <Breadcrumbs
                maintitle="academy"
                title="Attendence"
                breadcrumbItem="List"
              />
              <AttendenceTable />
            </div>
          </Container>
        </div>
      </AttendenceContextProvider>
    </React.Fragment>
  )
}

export default AttendenceListIndex
