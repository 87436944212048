import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap"

// Redux
import { connect } from "react-redux"
import { Link, useHistory, withRouter } from "react-router-dom"
// import "toastr/build/toastr.min.css";
// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation"
import jwtDecode from "jwt-decode"
// actions
import { apiError, loginUser } from "../../store/actions"
// import images
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import logoIcon from "../../assets/images/hdclogo.png"
import { postfunc } from "../../services/mainService"
const Login = () => {
  const [loading, setloading] = useState(false)
  const [load, setload] = useState(false)
  const [showpassword, setshowpassword] = useState(false)
  const [showicon, setshowicon] = useState("mdi mdi-eye")
  const [systemType, setsystemType] = useState("academy")
  const [responseMessage, setresponseMessage] = useState("")
  const [subscriptionObj, setsubscriptionObj] = useState({
    price: "",
    PhonePaid: "",
  })
  const [isOpen, setIsOpen] = useState(false)
  useEffect(() => {
    function onload() {
      localStorage.removeItem("token")
      sessionStorage.removeItem("token")
      localStorage.removeItem("studentToken")
    }
    onload()
  }, [])

  const notify = (message, type) => toast[type](message)
  // const notify = (message) => toast(message);
  const history = useHistory()

  const handlePaySubscription = async (event, values) => {
    try {
      // let checkpass= Object.keys(values).length
      // console.log("checkpass",checkpass)

      // if(checkpass<2) return toast.error("username or password is required", {theme: "dark"});
      // return console.log(values)

      let obj = {
        PhonePaid: subscriptionObj.PhonePaid,
        name: subscriptionObj.name,
      }
      // console.log("obj", obj)

      setload(true)
      const { data } = await postfunc("marchentPayment", obj)
      const { status, message, token } = data

      if (status) {
        toast.success(message, {
          theme: "colored",
        })
        setIsOpen(false)
        // setload(false)
      } else {
        toast.error(message, {
          theme: "colored",
        })
      }
      setload(false)
    } catch (error) {
      setload(false)
      toast.error(error.message, {
        theme: "colored",
      })
    }
  }

  const handleValidSubmit = async (event, values) => {
    try {
      // return
      // let checkpass= Object.keys(values).length
      // console.log("checkpass",checkpass)

      // if(checkpass<2) return toast.error("username or password is required", {theme: "dark"});
      // return console.log(values)

      let obj = { username: values.username, password: values.password }

      setloading(true)
      const { data } = await postfunc("login", obj)
      const { status, message, token, statusCode, userinfo } = data

      if (status) {
        setloading(false)
        const user = jwtDecode(token)
        toast.success(message, {
          theme: "colored",
        })
        //  return console.log(user)
        sessionStorage.setItem("brandType", "academy")

        if (user.userType == "teacher") {
          localStorage.setItem("token", token ? token : "")
          sessionStorage.setItem("token", token ? token : "")
          localStorage.setItem(
            "menus",
            user.menus ? JSON.stringify(user.menus) : []
          )
          toast.success(message, {
            theme: "colored",
          })
          history.push(`/attendence`)
        } else if (user.userType == "student") {
          toast.error("no permission to access", {
            theme: "dark",
          })
        } else {
          localStorage.setItem("token", token ? token : "")
          sessionStorage.setItem("token", token ? token : "")
          localStorage.setItem(
            "menus",
            user.menus ? JSON.stringify(user.menus) : []
          )
          toast.success(message, {
            theme: "colored",
          })

          // if (user.userType == "superAdmin") {
          //   history.push("/dashboard")
          //   return
          // }

          let manMenu = user.menus[0]
          if (manMenu.path != "") {
            history.push(manMenu.path)
          } else {
            let subMenu = manMenu.subMenu[0]
            history.push(subMenu.path)
          }
        }
      } else {
        if (statusCode != undefined && statusCode == 4000) {
          if (
            userinfo.userType == "admin" ||
            userinfo.userType == "superAdmin" ||
            userinfo.userType == "subscriber"
          )
            setIsOpen(true)
          setresponseMessage(message)
          setsubscriptionObj({
            price: `$${userinfo.price}`,
            PhonePaid: "",
            name: userinfo.name,
          })
        } else {
          toast.error(message, {
            theme: "colored",
          })
        }
        // notify(message, "error");

        setloading(false)
      }
    } catch (error) {
      setloading(false)
      toast.error(error.message, {
        theme: "colored",
      })
      // notify(message, "error");
    }
  }
  const hanldeRedirect = () => {
    window.open("http://zaki.ezyro.com/?i=1", "_blank")
  }
  const handleshow = () => {
    if (showpassword) {
      setshowicon("mdi mdi-eye")
    } else {
      setshowicon("mdi mdi-eye-off")
    }
    setshowpassword(!showpassword)
  }
  const tog_standard = () => {
    setload(false)
    setIsOpen(!isOpen)
  }
  const handleChange = e => {
    setsubscriptionObj(prev => {
      return { ...prev, [e.target.name]: e.target.value }
    })
  }
  return (
    <React.Fragment>
      <MetaTags>
        <title>Login | Human Diasgnostic Center</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div style={{ backgroundColor: "#14213D" }}>
                  <div className="text-primary text-center p-4">
                    <h5 className="text-white font-size-20">Welcome Back !</h5>
                    <p className="text-white-50">Hamar Diagnostic Center.</p>
                    <Link
                      to="/"
                      className="logo logo-admin "
                      style={{ borderBlockColor: "#E36167" }}
                    >
                      <img src={logoIcon} height="38" alt="logo" />
                    </Link>
                  </div>
                </div>

                <CardBody className="p-4">
                  <div className="p-3">
                    <AvForm
                      className="form-horizontal mt-4"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v)
                      }}
                    >
                      <Row>
                        <div className="mb-3">
                          <AvField
                            name="username"
                            label="Email"
                            // value="admin@themesbrand.com"
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            required
                          />
                        </div>

                        <div className="mb-3">
                          <AvField
                            name="password"
                            label="Password"
                            // value="123456"
                            type="password"
                            required
                            placeholder="Enter Password"
                          />
                        </div>
                      </Row>

                      <Row className="mb-3">
                        <Col sm={5}>
                          <div className="form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customControlInline"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customControlInline"
                            >
                              Remember me
                            </label>
                          </div>
                        </Col>
                        <Col sm={7} className="text-end">
                          <button
                            style={{ backgroundColor: "#14213D" }}
                            className="btn text-white w-md waves-effect waves-light "
                            type="submit"
                          >
                            {/* {loading ? (
                                  <Spinner className="float-center" style={{color:"success"}} />
                              ):"Log In"} */}
                            {loading ? (
                              <div>
                                <Spinner
                                  animation="border"
                                  role="status"
                                  style={{ width: "1rem", height: "1rem" }}
                                ></Spinner>
                              </div>
                            ) : (
                              "Log In"
                            )}
                          </button>
                        </Col>
                      </Row>

                      <>
                        <Row className="mt-2 mb-0 row">
                          <div className="col-8 mt-4">
                            <Link to="/forgot-password">
                              <i className="mdi mdi-lock"></i> Forgot your
                              password?
                            </Link>
                          </div>
                          <div className="col-4 mt-4">
                            <Link to="/loginportal">
                              <i className="on ion-md-list-box"></i> student
                              portal
                            </Link>
                          </div>
                        </Row>
                      </>

                      <div className="mt-3 text-center"></div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-3 text-center"></div>
            </Col>
          </Row>
          <ToastContainer />
          <Modal isOpen={isOpen} toggle={tog_standard}>
            <ModalHeader
              style={{
                background: "#14213D",
                color: "white",
                border: "#0A928C",
              }}
              toggle={tog_standard}
            >
              {" "}
              <p>Make Payment </p>
            </ModalHeader>
            <AvForm
              className="needs-validation"
              onValidSubmit={handlePaySubscription}
            >
              <ModalBody>
                <div className="alert alert-warning fade show">
                  {responseMessage}
                </div>
                <Row>
                  <Col>
                    <FormGroup>
                      <AvField
                        name="price"
                        label="price"
                        placeholder="Enter price"
                        disabled
                        type="text"
                        value={subscriptionObj.price}
                        onChange={e => handleChange(e)}
                        className="form-control mt-2"
                        errorMessage="Enter price"
                        validate={{
                          required: { value: true },
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col>
                    <FormGroup>
                      <AvField
                        name="PhonePaid"
                        label="phone"
                        placeholder="Enter phone"
                        type="text"
                        value={subscriptionObj.PhonePaid}
                        onChange={e => handleChange(e)}
                        className="form-control"
                        errorMessage="Enter phone"
                        validate={{
                          required: { value: true },
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter>
                <Button
                  type="button"
                  color="danger"
                  onClick={tog_standard}
                  className="waves-effect"
                >
                  Close
                </Button>

                <Button
                  type="submit"
                  // style={{
                  //   background: "#14213D",
                  //   color: "white",
                  // }}
                  disabled={load}
                  className="waves-effect waves-light bg-success text-white"
                >
                  {load ? (
                    <div>
                      <Spinner
                        animation="border"
                        role="status"
                        style={{ width: "1rem", height: "1rem" }}
                      ></Spinner>
                    </div>
                  ) : (
                    "Pay Now"
                  )}
                  {/* {load ? "saving..." : "  Save Note"} */}
                </Button>
              </ModalFooter>
            </AvForm>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { error } = state.Login
  return { error }
}

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError })(Login)
)

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
}
