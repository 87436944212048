import { useContext } from "react"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import {
  Button,
  Col,
  InputGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap"

import { AvField, AvForm } from "availity-reactstrap-validation"
import { teacherContext } from "../../context/teacherContext"
import "../Dashboard/datatables.scss"

const TeacherForm = () => {
  const {
    isOpen,
    editCondition,
    tog_standard,
    handleChange,
    HandleSubmitBtn,
    disabled,
    load,
    teacherObj,
    handleshow,
    showpassword,
    showicon,
  } = useContext(teacherContext)
  return (
    <Row>
      <Col sm={6} md={4} xl={3} className="mt-4">
        <div className="text-center"></div>

        <Modal isOpen={isOpen} toggle={tog_standard}>
          <ModalHeader
            style={{
              background: "#14213D",
              color: "white",
              border: "#0A928C",
            }}
            toggle={tog_standard}
          >
            {" "}
            <p> Teacher Registration</p>
          </ModalHeader>
          <AvForm className="needs-validation" onValidSubmit={HandleSubmitBtn}>
            <ModalBody>
              <Row>
                <Col lg={6}>
                  <div className="mb-3">
                    <AvField
                      name="teacherName"
                      type="text"
                      label="Name"
                      value={teacherObj.teacherName}
                      onChange={e => handleChange(e)}
                      placeholder="Enter Teacher Name"
                      errorMessage="Enter Teacher Name"
                      validate={{
                        required: { value: true },
                      }}
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mb-3">
                    <AvField
                      name="teacherPhone"
                      type="number"
                      label="Phone"
                      value={teacherObj.teacherPhone}
                      onChange={e => handleChange(e)}
                      placeholder="Enter  Phone"
                      errorMessage="Enter Phone"
                      validate={{
                        required: { value: true },
                      }}
                    ></AvField>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <div className="mb-3">
                    <AvField
                      name="teacherGender"
                      type="select"
                      label="Gender"
                      value={teacherObj.teacherGender}
                      onChange={e => handleChange(e)}
                      placeholder="Enter Gender"
                      errorMessage="Enter Gender"
                      validate={{
                        required: { value: true },
                      }}
                    >
                      <option vlaue="">Select Gender</option>
                      <option vlaue="male">male</option>
                      <option vlaue="female">female</option>
                    </AvField>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mb-3">
                    <AvField
                      name="teacherAddress"
                      type="text"
                      label="Address"
                      value={teacherObj.teacherAddress}
                      onChange={e => handleChange(e)}
                      placeholder="Enter  Address"
                      errorMessage="Enter Address"
                      validate={{
                        required: { value: true },
                      }}
                    ></AvField>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col lg={6}>
                  <div className="mb-3">
                    <AvField
                      name="educationalLevel"
                      type="select"
                      label="educational Level"
                      value={teacherObj.educationalLevel}
                      onChange={e => handleChange(e)}
                      placeholder="Enter  educational Level"
                      errorMessage="Enter educational Level"
                      validate={{
                        required: { value: true },
                      }}
                    >
                      <option vlaue="">Select educational Level</option>
                      <option vlaue="Phd">Phd</option>
                      <option vlaue="Master">Master</option>
                      <option vlaue="Bachelor">Bachelor</option>
                      <option vlaue="Secondary">Secondary</option>
                      <option vlaue="primary">primary</option>
                      <option vlaue="others">Others</option>
                    </AvField>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mb-3">
                    <AvField
                      name="teacherStatus"
                      type="select"
                      label="teacher Status"
                      value={teacherObj.teacherStatus}
                      onChange={e => handleChange(e)}
                      placeholder="Enter teacher Status"
                      // errorMessage="Enter teacher Status"
                      // validate={{
                      //   required: { value: true },
                      // }}
                    >
                      <option vlaue="active">active</option>
                      <option vlaue="pending">pending</option>
                    </AvField>
                  </div>
                </Col>
              </Row>
              {/* <hr></hr> */}
              <Row>
                <Col lg={6}>
                  <div className="mb-3">
                    <AvField
                      name="username"
                      type="email"
                      label="username (Email)"
                      value={teacherObj.username}
                      onChange={e => handleChange(e)}
                      placeholder="Enter  username"
                      errorMessage="Enter username "
                      validate={{
                        required: { value: true },
                      }}
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  {/* <div className="mb-3">
                  <AvField
                    name="password"
                    type="password"
                    label="password"
                    value={teacherObj.password}
                    onChange={(e) => handleChange(e)}
                    placeholder="Enter  password"
                    errorMessage="Enter password"
                    validate={{
                      required: { value: true },
                    }}
                  >
                  </AvField>
                </div> */}
                  <div>
                    <Label>password</Label>
                    <InputGroup>
                      <input
                        type={showpassword ? "text" : "password"}
                        value={teacherObj.password}
                        name="password"
                        onChange={e => handleChange(e)}
                        className="form-control"
                        required
                        placeholder="enter password"
                        validate={{ required: { value: true } }}
                      />
                      <span
                        className="input-group-append"
                        onClick={() => handleshow()}
                      >
                        <span className="input-group-text">
                          {" "}
                          <i className={showicon}></i>
                        </span>
                      </span>
                    </InputGroup>
                  </div>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button
                type="button"
                color="danger"
                onClick={tog_standard}
                className="waves-effect"
              >
                Close
              </Button>
              <Button
                type="submit"
                style={{
                  background: "#14213D",
                  color: "white",
                  border: "#0A928C",
                }}
                disabled={disabled}
                className="waves-effect waves-light"
              >
                {load ? "saving..." : "Save Teacher"}
              </Button>
            </ModalFooter>
          </AvForm>
        </Modal>
      </Col>
      <ToastContainer />
    </Row>
  )
}

export default TeacherForm
