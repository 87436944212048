import React from "react"
import MetaTags from "react-meta-tags"
import { Container } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import EnrollmentContextProvider from "../../context/enrollmentContext"
import "../Dashboard/datatables.scss"
import EnrollmentForm from "./enrollmentForm"
import EnrollmentTable from "./enrollmentTable"

const Enrollment = () => {
  return (
    <React.Fragment>
      <EnrollmentContextProvider>
        <div className="page-content">
          <MetaTags>
            <title>Enrollment | Human Diasgnostic Center</title>
          </MetaTags>
          <Container fluid>
            <div className="page-title-box">
              <Breadcrumbs
                maintitle="Veltrix"
                title="Enrollment"
                breadcrumbItem="enrollment"
              />
              <EnrollmentForm />
              <EnrollmentTable />
            </div>
          </Container>
        </div>
      </EnrollmentContextProvider>
    </React.Fragment>
  )
}

export default Enrollment
