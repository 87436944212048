import React from "react"
import MetaTags from "react-meta-tags"
import { Card, CardBody, Col, Container, Row, Spinner } from "reactstrap"

// Redux
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"

// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation"

// action
import { userForgetPassword } from "../../store/actions"

// import images
import { useState } from "react"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import logoIcon from "../../assets/images/icon.png"
import { activationCodeSend, forgetPassword } from "../../services/mainService"
const ForgetPasswordPage = ({ history }) => {
  const [showinputs, setshownputs] = useState({
    showaEmailInput: true,
    showActivationInput: false,
    showpasswordInput: false,
    showconfirmpasswordInput: false,
  })
  const [loading, setloading] = useState(false)
  const [showactivationInput, setshowactivationInput] = useState(false)
  const [currentActivationCode, setcurrentActivationCode] = useState(0)
  const [loginObj, setloginObj] = useState({
    email: "",
    password: "",
    activationCode: "",
    confirmPassword: "",
  })

  const handleSubmit = async e => {
    try {
      if (
        showinputs.showaEmailInput == true &&
        showinputs.showActivationInput == false &&
        showinputs.showpasswordInput == false &&
        showinputs.showconfirmpasswordInput == false
      ) {
        delete loginObj.password
        delete loginObj.activationCode
        delete loginObj.confirmPassword
        // return console.log(loginObj)

        setloading(true)
        const { data } = await activationCodeSend("login", loginObj)
        console.log("data", data)
        const { status, message, activationCode } = data
        if (status) {
          setloading(false)
          toast.success(message, {
            theme: "colored",
          })
          // toast.success(message);
          setshownputs({
            showaEmailInput: true,
            showActivationInput: true,
            showpasswordInput: false,
            showconfirmpasswordInput: false,
          })
          setcurrentActivationCode(activationCode)
        } else {
          setloading(false)
          toast.error(message, {
            theme: "colored",
          })
        }
        return
      }
      if (
        showinputs.showaEmailInput == true &&
        showinputs.showActivationInput == true &&
        showinputs.showpasswordInput == false &&
        showinputs.showconfirmpasswordInput == false
      ) {
        if (loginObj.activationCode == currentActivationCode) {
          setshownputs({
            showaEmailInput: false,
            showActivationInput: false,
            showpasswordInput: true,
            showconfirmpasswordInput: true,
          })
        } else {
          toast.warning("invalid activation code", {
            theme: "colored",
          })
        }
        return
      }
      if (
        showinputs.showaEmailInput == false &&
        showinputs.showActivationInput == false &&
        showinputs.showpasswordInput == true &&
        showinputs.showconfirmpasswordInput == true
      ) {
        setloading(true)
        const { data } = await forgetPassword("login", loginObj)
        const { status, message } = data
        if (status) {
          setloading(false)
          toast.success(message)

          // setTimeout(() => {
          //   history.push("/login");
          //   setshownputs({
          //     showaEmailInput: true,
          //     showActivationInput: false,
          //     showpasswordInput: false,
          //     showconfirmpasswordInput: false,
          //   })
          // }, 7000);
        } else {
          toast.error(message, {
            theme: "colored",
          })
          setloading(false)
        }
      }
    } catch (error) {
      toast.error(error.message, {
        theme: "colored",
      })
      setloading(false)
    }
  }
  const handleChange = e => {
    setloginObj(prev => {
      // console.log(e.target.name + " ", e.target.value);
      return { ...prev, [e.target.name]: e.target.value }
    })
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Forget Password | Human Diasgnostic Center</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2"></i>
        </Link>
      </div>
      <div className="account-pages my-5 pt-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={4}>
              <Card className="overflow-hidden">
                <div className="" style={{ backgroundColor: "#14213D" }}>
                  <div className="text-primary text-center p-4">
                    <h5 className="text-white font-size-20 p-2">
                      Forget Password
                    </h5>
                    <a
                      href="index.html"
                      className="logo logo-admin"
                      style={{ borderBlockColor: "#E27356" }}
                    >
                      <img src={logoIcon} height="38" alt="logo" />
                    </a>
                  </div>
                </div>
                <CardBody className="p-4">
                  <ToastContainer />

                  <AvForm
                    className="form-horizontal mt-4"
                    onValidSubmit={handleSubmit}
                  >
                    {showinputs.showaEmailInput && (
                      <div className="mb-3">
                        <AvField
                          name="email"
                          label="Email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          value={loginObj.email}
                          onChange={e => handleChange(e)}
                          errorMessage="please Enter your email"
                          validate={{
                            required: { value: true },
                            email: {
                              value: true,
                              errorMessage: "email must be a valid email",
                            },
                          }}
                        />
                      </div>
                    )}

                    {showinputs.showActivationInput && (
                      <div className="mb-3">
                        <p>
                          We have sent you verification email {loginObj.email},
                          Please check it.{" "}
                        </p>
                        <AvField
                          name="activationCode"
                          label="activation Code"
                          value={loginObj.activationCode}
                          onChange={e => handleChange(e)}
                          className="form-control"
                          placeholder="Enter activationCode"
                          type="text"
                          required
                        />
                      </div>
                    )}

                    {showinputs.showconfirmpasswordInput && (
                      <div className="mb-3">
                        <AvField
                          name="password"
                          label="password"
                          value={loginObj.password}
                          onChange={e => handleChange(e)}
                          className="form-control"
                          placeholder="Enter password"
                          type="password"
                          validate={{
                            required: { value: true },
                            match: {
                              value: "password",
                              errorMessage: "not matched ",
                            },

                            minLength: {
                              value: 5,
                              errorMessage:
                                "Your password must be more then 4 character",
                            },
                          }}
                        />
                      </div>
                    )}
                    {showinputs.showconfirmpasswordInput && (
                      <div className="mb-3">
                        <AvField
                          name="confirmPassword"
                          label="confirmPassword"
                          value={loginObj.confirmPassword}
                          onChange={e => handleChange(e)}
                          className="form-control"
                          placeholder="Enter confirmPassword"
                          type="password"
                          errorMessage="Enter password"
                          validate={{
                            required: { value: true },
                            match: {
                              value: "password",
                              errorMessage: "not matched ",
                            },

                            minLength: {
                              value: 5,
                              errorMessage:
                                "Your password must be more then 4 character",
                            },
                          }}
                        />
                      </div>
                    )}
                    <Row className="mb-3">
                      <Col className="text-end">
                        <button
                          style={{ backgroundColor: "#14213D" }}
                          className="btn btn-primary w-md waves-effect waves-light"
                          type="submit"
                        >
                          {loading ? (
                            <div>
                              <Spinner
                                animation="border"
                                role="status"
                                style={{ width: "1rem", height: "1rem" }}
                              ></Spinner>
                            </div>
                          ) : (
                            " Reset"
                          )}
                        </button>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <Link to="/loginportal">
                  <i className="on ion-md-list-box"></i> student portal
                </Link>
                {/* <p>Remember It ? <Link to="login" className="fw-medium text-primary"> Sign In here </Link> </p> */}
                <p>
                  Remember It ?{" "}
                  <Link to="login" className="fw-medium text-primary">
                    {" "}
                    Sign In here{" "}
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} Developed{" "}
                  <i className="mdi mdi-heart text-danger" /> by Kaahiye Tech
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

// ForgetPasswordPage.propTypes = {
//   forgetError: PropTypes.any,
//   forgetSuccessMsg: PropTypes.any,
//   history: PropTypes.object,
//   userForgetPassword: PropTypes.func
// }

const mapStatetoProps = state => {
  const { forgetError, forgetSuccessMsg } = state.ForgetPassword
  return { forgetError, forgetSuccessMsg }
}

export default withRouter(
  connect(mapStatetoProps, { userForgetPassword })(ForgetPasswordPage)
)
