import { MDBDataTable } from "mdbreact"
import React, { useState } from "react"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
// import axios from "axios"
import { AvField, AvForm } from "availity-reactstrap-validation"
import moment from "moment"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap"
// import { FetchTrensections } from "./TransectionApi";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
//Import Components
import axios from "axios"
import { useLocation } from "react-router-dom"
import useSwr, { mutate } from "swr"
import Auhtentication from "../../authMidlleware/userMiddleWare"
import { postfunc, putfunc } from "../../services/mainService"
const Customers = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [editCondition, setEditCondition] = useState(false)
  const [customerObj, setcustomerObj] = useState({
    name: "",
    phone: "",
    gender: "",
    address: "",
  })
  const [disabled, setdisabled] = useState(false)
  const [load, setload] = useState(false)

  const token = localStorage.getItem("token")
  const headers = {
    headers: {
      "Content-Type": "application/json",
      "auth-token": token,
    },
  }
  let epiEndPoint = process.env.REACT_APP_ENDPOINT
  const epiEndPointDecoration = process.env.REACT_APP_DECORATION_ENDPOINT

  async function fetchAll(path) {
    let brandType = sessionStorage.getItem("brandType")
    if (brandType == "decoration") {
      epiEndPoint = epiEndPointDecoration
    }
    const { data } = await axios.get(epiEndPoint + "/" + path, headers)
    return data
  }

  const location = useLocation()
  Auhtentication(location.pathname)

  const { data: customerslist } = useSwr("customers", fetchAll)
  let customersData = []

  let chechArray = Array.isArray(customerslist)
  if (chechArray == true) {
    customersData = customerslist
  }
  const tog_standard = () => {
    setIsOpen(!isOpen)
    // setcustomerObj({
    //   date: new Date(),
    //   amount: "",
    //   description: "",
    // });

    // setEditCondition(!editCondition);
  }
  const breadcrumbItems = [
    { title: "minibank", link: "#" },
    { title: "customer", link: "#" },
  ]
  const handleChange = e => {
    setcustomerObj(prev => {
      return { ...prev, [e.target.name]: e.target.value }
    })
  }
  const notify = (message, type) => toast[type](message)
  const HandleSubmitBtn = async e => {
    try {
      setdisabled(true)
      setload(true)
      e.preventDefault()
      const token = localStorage.getItem("token")

      if (editCondition) {
        const { data } = await putfunc("customers", customerObj, headers)
        console.log(data.info)
        const { status, message, info } = { ...data }
        if (status) {
          setdisabled(true)
          setload(true)
          mutate("customers", fetchAll)
          notify(message, "success")
          setIsOpen(false)
          setEditCondition(false)
        } else {
          setdisabled(false)
          setload(false)
          notify(message, "error")
        }
      } else {
        if (customerObj.address == "") {
          customerObj.address = "empty"
        }
        //   return   console.log("customerObj",customerObj);
        const { data } = await postfunc("customers", customerObj, headers)
        const { status, message, info } = { ...data }
        if (status) {
          setdisabled(false)
          setload(false)
          setIsOpen(false)

          notify(message, "success")
          mutate("customers", fetchAll)
          setcustomerObj({
            date: new Date(),
            description: "",
            amount: "",
          })
        } else {
          setdisabled(false)
          setload(false)
          notify(message, "error")
        }
      }
    } catch (error) {
      console.log(error.response)
      notify(error.message, "error")
      setdisabled(false)
      setload(false)
    }
  }

  const handleEdit = async transData => {
    let data = { ...transData }
    setEditCondition(true)
    setcustomerObj(data)
    setIsOpen(true)
  }

  const UserData = {
    columns: [
      {
        label: "Code",
        field: "code",
        sort: "asc",
        width: 40,
      },
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Phone",
        field: "phone",
        sort: "asc",
        width: 60,
      },
      {
        label: "Gender",
        field: "gender",
        sort: "asc",
        width: 50,
      },
      {
        label: "Created Date",
        field: "date",
        sort: "asc",
        width: 270,
      },

      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 50,
      },
    ],
    rows: customersData?.map(cust => {
      const data = { ...cust }

      data.date = moment(data.createdAt).format("lll")

      data.action = (
        <div>
          <button
            onClick={() => handleEdit(cust)}
            type="button"
            className="btn btn-white btn-sm mr-4"
          >
            <i
              style={{
                color: "#14213D",
              }}
              className="mdi mdi-pencil font-size-20 "
            ></i>
          </button>
          {/* <button
            type="button"
            onClick={() => handleDelete(cust)}
            className="btn btn-white btn-sm"
          >
            <i className="mdi mdi-trash-can font-size-20 text-danger "></i>
          </button> */}
        </div>
      )
      return data
    }),
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Customers" breadcrumbItems={breadcrumbItems} />
          <Row>
            <Col sm={6} md={4} xl={3} className="mt-4">
              <div className="text-center"></div>
              <Modal isOpen={isOpen} toggle={tog_standard}>
                <ModalHeader
                  style={{
                    background: "#14213D",
                    color: "white",
                    border: "#0A928C",
                  }}
                  toggle={tog_standard}
                >
                  {" "}
                  <p>Customer Registration</p>
                </ModalHeader>
                <AvForm
                  className="needs-validation"
                  onValidSubmit={HandleSubmitBtn}
                >
                  <ModalBody>
                    <Row>
                      <Col>
                        <FormGroup>
                          <AvField
                            name="name"
                            label="Name"
                            placeholder="Enter full name ...."
                            type="text"
                            value={customerObj.name}
                            onChange={e => handleChange(e)}
                            className="form-control"
                            errorMessage="Enter name"
                            validate={{
                              required: { value: true },
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <AvField
                            type="number"
                            name="phone"
                            label="Phone"
                            value={customerObj.phone}
                            onChange={e => handleChange(e)}
                            placeholder="Eneter Phone"
                            errorMessage="Enter Phone"
                            validate={{
                              required: { value: true },
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className="mt-3">
                      <Col>
                        <FormGroup>
                          <AvField
                            type="text"
                            label="Address (Optinal)"
                            name="address"
                            value={customerObj.address}
                            onChange={e => handleChange(e)}
                            placeholder="Eneter address"
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <AvField
                            type="select"
                            label="gender"
                            name="gender"
                            value={customerObj.gender}
                            onChange={e => handleChange(e)}
                            errorMessage="Select gender"
                            validate={{
                              required: { value: true },
                            }}
                          >
                            <option value="">Select gender</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                          </AvField>
                        </FormGroup>
                      </Col>
                    </Row>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      type="button"
                      color="danger"
                      onClick={tog_standard}
                      className="waves-effect"
                    >
                      Close
                    </Button>

                    <Button
                      type="submit"
                      style={{
                        background: "#14213D",
                        color: "white",
                      }}
                      disabled={disabled}
                      className="waves-effect waves-light"
                    >
                      {load ? "saving..." : " Save customer"}
                    </Button>
                  </ModalFooter>
                </AvForm>
              </Modal>
            </Col>
          </Row>
          <ToastContainer />

          {/* data table Row  */}
          <Row>
            <Col xs={12}>
              <Card>
                {/* <CardHeader  style={{background:"#14213D",color:"white"}}>
                 Here you can register your customer and also get's customers record.
                </CardHeader> */}
                <CardBody>
                  <Row className="mb-2">
                    <Col sm={6} md={8} xl={10}>
                      <h4 className="card-title">customers Table </h4>
                    </Col>
                    <Col sm={6} md={4} xl={2}>
                      <Button
                        type="button"
                        onClick={tog_standard}
                        style={{ background: "#14213D", color: "white" }}
                        className="waves-effect waves-light float-right"
                      >
                        <i className="ri-add-line"></i> Add Customer
                      </Button>
                    </Col>
                  </Row>

                  <MDBDataTable responsive striped bordered data={UserData} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Customers
