import { MDBDataTable } from "mdbreact"
import moment from "moment"
import { useContext } from "react"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Button, Card, CardBody, CardTitle, Col, Row } from "reactstrap"
import { teacherContext } from "../../context/teacherContext"
import "../Dashboard/datatables.scss"
const TeacherTable = () => {
  const { isOpen, editCondition, teacherData, tog_standard, handleEdit } =
    useContext(teacherContext)

  const data = {
    columns: [
      {
        label: "Teacher Name",
        field: "teacherName",
        sort: "asc",
        width: 150,
      },
      {
        label: "Phone",
        field: "teacherPhone",
        sort: "asc",
        width: 270,
      },
      {
        label: "Gender",
        field: "teacherGender",
        sort: "asc",
        width: 200,
      },

      {
        label: "Address",
        field: "teacherAddress",
        sort: "asc",
        width: 100,
      },
      {
        label: "educationalLevel",
        field: "educationalLevel",
        sort: "asc",
        width: 100,
      },
      {
        label: "Status",
        field: "teacherStatus",
        sort: "asc",
        width: 100,
      },
      {
        label: "Created Date",
        field: "date",
        sort: "asc",
        width: 100,
      },

      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 100,
      },
    ],
    rows: teacherData?.map(hos => {
      const data = { ...hos }
      data.date = moment(data.createdAt).format("lll")
      data.price = "$" + data.coursePrice
      data.action = (
        <div>
          <button
            onClick={() => handleEdit(hos)}
            type="button"
            className="btn btn-white btn-sm mr-4"
          >
            <i
              style={{
                color: "#14213D",
              }}
              className="mdi mdi-pencil font-size-20 "
            ></i>
          </button>
        </div>
      )
      return data
    }),
  }
  return (
    <Row>
      <Col className="col-12">
        <Card>
          <CardBody>
            <Row className="align-items-center  mb-3">
              <Col md={8}>
                <CardTitle className="h4">Teachers Table </CardTitle>
              </Col>

              <Col md="4">
                <div className="float-end d-none d-md-block">
                  <Button
                    onClick={tog_standard}
                    style={{
                      background: "#14213D",
                      color: "white",
                      border: "#0A928C",
                    }}
                    className=" dropdown-toggle waves-effect waves-light"
                  >
                    <i className="ion ion-md-add me-2"></i> Add Teacher
                  </Button>
                </div>
              </Col>
            </Row>
            <ToastContainer />

            <MDBDataTable responsive striped bordered data={data} />
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default TeacherTable
