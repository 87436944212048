import React from "react"
import MetaTags from "react-meta-tags"
import { Container } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TeacherContextProvider from "../../context/teacherContext"
import "../Dashboard/datatables.scss"
import TeacherForm from "./teacherForm"
import TeacherTable from "./teacherTable"

const Teacher = () => {
  return (
    <React.Fragment>
      <TeacherContextProvider>
        <div className="page-content">
          <MetaTags>
            <title>Teachers |Human Diasgnostic Center</title>
          </MetaTags>
          <Container fluid>
            <div className="page-title-box">
              <Breadcrumbs
                maintitle="Veltrix"
                title="class"
                breadcrumbItem="class"
              />
              <TeacherForm />
              <TeacherTable />
            </div>
          </Container>
        </div>
      </TeacherContextProvider>
    </React.Fragment>
  )
}

export default Teacher
