import { MDBDataTable } from "mdbreact"
import moment from "moment"
import { useContext } from "react"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Button, Card, CardBody, CardTitle, Col, Row } from "reactstrap"
import { courseContext } from "../../context/coursesContext"
import "../Dashboard/datatables.scss"
const CoursesTable = () => {
  const { isOpen, editCondition, coursesData, tog_standard, handleEdit } =
    useContext(courseContext)

  const data = {
    columns: [
      {
        label: "course Name",
        field: "courseName",
        sort: "asc",
        width: 150,
      },
      {
        label: "course Price",
        field: "price",
        sort: "asc",
        width: 270,
      },
      {
        label: "Duration",
        field: "courseDuration",
        sort: "asc",
        width: 200,
      },

      {
        label: "courseLevel",
        field: "courseLevel",
        sort: "asc",
        width: 100,
      },
      {
        label: "Created Date",
        field: "date",
        sort: "asc",
        width: 100,
      },

      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 100,
      },
    ],
    rows: coursesData?.map(hos => {
      const data = { ...hos }
      data.date = moment(data.createdAt).format("lll")
      data.price = "$" + data.coursePrice
      data.action = (
        <div>
          <button
            onClick={() => handleEdit(hos)}
            type="button"
            className="btn btn-white btn-sm mr-4"
          >
            <i
              style={{
                color: "#14213D",
              }}
              className="mdi mdi-pencil font-size-20 "
            ></i>
          </button>
        </div>
      )
      return data
    }),
  }
  return (
    <Row>
      <Col sm={6} md={12} xl={12}>
        <Card>
          <CardBody>
            <Row className="align-items-center  mb-3">
              <Col xs={6}>
                <CardTitle className="h4">Courses Table </CardTitle>
              </Col>

              <Col xs={6}>
                <div className="float-end d-none d-md-block">
                  <Button
                    onClick={tog_standard}
                    style={{
                      background: "#14213D",
                      color: "white",
                      border: "#0A928C",
                    }}
                    className=" dropdown-toggle waves-effect waves-light"
                  >
                    <i className="ion ion-md-add me-2"></i> Add Course
                  </Button>
                </div>
              </Col>
            </Row>
            <ToastContainer />

            <MDBDataTable responsive striped bordered data={data} />
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default CoursesTable
